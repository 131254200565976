import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Constant } from "../../../commom/Constants";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Yourinvestment from "../Cards/Yourinvestment";
import { CommonDataTable } from "../SharedComponent/CommonDataTable";
import RightSideSection from "../SharedComponent/RightSideSection";
import showPremiumAlert, { formatPrice, getCustomerInfoFromLocalStorage } from "../../../Utils/utils";
function MarketSignals() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const customerInfo = getCustomerInfoFromLocalStorage();
  const isPremium = customerInfo?.entitlements?.active?.Premium?.isActive;

  const [totalRows] = useState(10);
  const [marketsignalselect, setmarketsignalselect] = React.useState<any>("bullish");
  const [marketSignalCategories, setMarketSignalCategories] = useState<any>("bullish");
  const [marketSignalOpt, setMarketsignalOpt] = React.useState<any>("");
  const [marketSignalFilers, setMarketSignalFilers] = useState<any>([])
  const [marketSignalFilersData, setMarketSignalFilersData] = useState<any>([])
  const [loading, setLoading] = useState(false);

  const isHeader = location.state?.isHeader;
  // ** Table Headers

  const columns = [
    {
      name: "Company",
      selector: (row) => row.company,
      cell: (row) => (
        <div
          onClick={() => {
            navigateToDetail(row);
          }}
          className="stock-label"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <Avatar
            className="stock-icon"
            alt={row.ticker}
            src={row.branding?.icon_url}
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "white",
              objectFit: "fill",
            }}
          />
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.company}
          </span>
        </div>
      ),
      sortable: true,
      center: false,
      width: "350px",
      //width: "auto",
    },
    {
      name: "Market Price",
      selector: (row) => row.price,
      cell: (row) => (
        <div>
          {row.price === null ? "-" : `$${row.price?.toFixed(2)}`}
        </div>
      ),
      width: "auto",
      // width: "a",
      sortable: true,
      center: true,
    },
    {
      name: "Change",
      selector: (row) => row.change,
      cell: (row) => {
        return (
          <div
            style={{
              color: row.change === 0 ? '' : row.change > 0 ? '#4edf6d' : 'red',
            }}
          >
            {row.change === 0 ? (
              <span style={{ color: "white" }}>N/A</span>
            ) : row.change > 0 ? (
              <span style={{ color: "green" }}>+${formatPrice(row.change)}</span>
            ) : (
              <span style={{ color: "red" }}>-${formatPrice(Math.abs(row.change))}</span>
            )}
          </div>
        )
      },

      sortable: true,
      width: "auto",
      center: false,
    },

    {
      name: "Percentage",
      selector: (row) => row.change_percent,
      sortable: true,
      cell: (row) => {

        return (
          <div
            style={{
              color: row.change_percent === 0 ? '' : row.change_percent > 0 ? '#4edf6d' : 'red',
            }}
          >
            {row.change_percent === 0 ? (
              <span style={{ color: "white" }}>N/A</span>
            ) : row.change_percent > 0 ? (
              <span style={{ color: "green" }}>+{formatPrice(row.change_percent)}%</span>
            ) : (
              <span style={{ color: "red" }}>{formatPrice(row.change_percent)}%</span>
            )}
          </div>
        );
      },
      width: 'auto',
      center: false,
    },
  ];
  const navigateToDetail = async (data) => {
    //stockdetail
    navigation("/stockdetail", {
      state: {
        symbol: data.ticker,
        icon: data.branding?.logo_url ?? data.branding?.icon_url,
        title: "Market Signals"
      },
    });
  };


  const handleChangeMarketSingnalsSelect = (event: SelectChangeEvent) => {
    setmarketsignalselect(event.target.value as string);
    const data = marketSignalFilers.find((item) => item.signal === event.target.value);
    setMarketSignalCategories(data)
    setMarketsignalOpt(data.categories[0].name);
  };

  const onMarketSignalFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMarketsignalOpt(newAlignment);
  };


  const getMarketSignalsFilters = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.Market.GetMarketSignalsFilters
      );
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilers(resData.data);
        setMarketsignalOpt(resData.data[marketsignalselect]?.[0].key);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setLoading(false);
    }
  };

  const getMarketSignalsCategories = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.Market.GetMArketSignalCategories
      );
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilers(resData.data);
        setmarketsignalselect(resData.data[0].signal);
        setMarketSignalCategories(resData.data[0]);
        setMarketsignalOpt(resData.data[0].categories[0].name);
      }
      setLoading(false)
    } catch (error: any) {
      console.error("ERRR : ", error);
      setLoading(false);
    } finally {
    }
  }


  const getMarketSignalsFilterData = async () => {
    setLoading(true);
    const data = {
      filterKey: marketSignalOpt,
    };
    try {
      const response = await Axios.get(
        UrlConstant.Market.GetMarketSignalByCategory.replace("{0}", marketsignalselect).replace("{1}", marketSignalOpt))
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilersData(resData.data ?? []);
      }
      setLoading(false);
    } catch (error: any) {
      console.error("ERRR : ", error);
      setLoading(false);
    } finally {

    }
  };


  useEffect(() => {
    getMarketSignalsCategories();
    // getMarketSignalsFilters();
  }, []);

  useEffect(() => {
    if (marketSignalOpt) {
      getMarketSignalsFilterData()
    }
  }, [marketSignalOpt])


  return (
    <Box className="bg-color" width='100%' display="flex">
      <Container
        maxWidth={false}
        className="bg-color p-3 "
        style={{
          minHeight: "calc(100vh -  71px - 143px)",
          // height:'100vh',
          width: "60%",
        }}
      >
        {
          !isHeader ?
            <Typography
              className="text-secondary"
              sx={{ marginBottom: "20px", paddingTop: '30px' }}
            >
              {" "}
              <span
                onClick={() => navigation(-1)}
                style={{ color: "#4A7AF2", cursor: "pointer" }}
              >
                Dashboard{" "}
              </span>{" "}
              / Market Signals
            </Typography> : ''
        }

        <Box mb={5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center" mb={1}>
              <Typography
                className="text-white"
                mb={0}
                style={{ fontSize: "1.5rem" }}
              >
                Market Signals
              </Typography>

              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select2"
                  value={marketsignalselect}
                  className="text-white"
                  sx={{
                    // color:'!important',
                    borderRadius: "9px !important",
                    padding: "0px !important",
                    '.MuiSvgIcon-root': {
                      color: 'white !important'
                    }
                  }}
                  onChange={handleChangeMarketSingnalsSelect}
                >
                  {marketSignalFilers.map((item) => {

                    return (
                      <MenuItem value={item.signal} selected>
                        {item.signal}
                      </MenuItem>
                    )
                  })
                  }
                </Select>
              </FormControl>

            </Box>
          </Box>

          <Box mb={1} maxWidth='100%' overflow='hidden'>
            <ToggleButtonGroup
              // className="border-white"
              // color="primary"
              value={marketSignalOpt}
              exclusive
              onChange={(event, newFilter) => {
                if (newFilter !== null) {
                  if (marketSignalCategories?.categories?.findIndex((item) => item.name === newFilter) > 0 && !isPremium) {
                    showPremiumAlert(navigate)
                  } else {
                    onMarketSignalFilterChange(event, newFilter);
                  }
                }
              }}
              aria-label="Platform"
            >
              {
                marketSignalCategories?.categories?.map((filter) => {
                  return (
                    <ToggleButton value={filter.name} >
                      <Box sx={{ textWrap: 'nowrap' }} display="flex" justifyContent="space-between" alignItems="center">
                        <Avatar
                          alt={filter?.name}
                          src={marketSignalOpt == filter.name ? filter?.selectedIconUrl : filter?.iconUrl}
                          sx={{ width: 20, height: 20, marginRight: "5px" }}
                        />
                        {filter.name}
                      </Box>
                    </ToggleButton>
                  )
                })
              }
            </ToggleButtonGroup>

          </Box>
        </Box>
        <Container
          style={{ width: "100%", left: "0", margin: "0" }}
          className="p-0"
        >
          <Row className="dark-tbl no-border p-0">
            <Card className="custom-card overflow-hidden bg-color w-100">
              <Card.Body className="ps-12 p-2" style={{ backgroundColor: 'transparent' }}>
                <div>
                  {loading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="200px"
                      bgcolor='transparent'
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  ) : (
                    <CommonDataTable
                      setPage={setPage}
                      setRowsPerPage={setRowsPerPage}
                      data={marketSignalFilersData}
                      totalRows={totalRows}
                      columns={columns}
                      setSearch={setSearch}
                      title={"Market Signals"}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        {/* <Container style={{ width: "70%", left: "0", margin: "0" }}>
          <TotalFunds />
        </Container> */}
      </Container>
      <Container style={{ width: "35%", paddingTop: "20px" }}>
        <RightSideSection />
      </Container>
    </Box>
  );
}
export default MarketSignals;
