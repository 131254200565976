import { ExpandCircleDownOutlined } from "@mui/icons-material";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import {
  Skeleton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  IconButton,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import "../../../style/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../Redux/authSlice";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import showPremiumAlert, { formatPrice, getCustomerInfoFromLocalStorage } from "../../../Utils/utils";
import { Constant } from "../../../commom/Constants";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import Watchlistcard from "../Cards/watchlistcard";
import BoosterLoanCard from "../Cards/BoosterLoancard";
import moment from "moment";
import LoadingCards from "./LoadingCards";
import RightSideSection from "../SharedComponent/RightSideSection";

import axios from "axios";
import LinechartMini from "../charts/LinechartMini";
import LoadingMiniCharts from "./LoadingMiniCharts";

const BAR_CHART_KEY = "0J7lfEpAefOuHSE6uSHdiW_5N0sThxfa";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#2A2A2A', // Base color
        },
        pulse: {
          '&::after': {
            background: 'linear-gradient(90deg, #2A2A2A, #3C3C3C)', // Highlight animation
          },
        },
      },
    },
  },
});
function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerInfo = getCustomerInfoFromLocalStorage();
  const isPremium = customerInfo?.entitlements?.active?.Premium?.isActive;
  // AI Picks Starts
  const [allAiPickData, setAllAiPickData] = useState<any>({});
  const [aiPicksList, setAiPicksList] = useState([]);
  const [aiPicksLoading, setAiPicksLoading] = useState<any>(false);
  const [buyOrSell, setBuyOrSell] = useState<any>(Constant.BUY);
  const [stockOrETF, setStockOrETF] = useState<any>(Constant.STOCK);
  const [selectedCategory, setSelectedCategory] = useState<String>(Constant.POPULAR);
  // AI Picks Starts

  const [watchList, setWatchList] = useState([]);
  const [watchlistLoading, setWatchlistLoading] = useState<any>(false);
  const [allMyPickData, setAllMyPickData] = useState<any>([]);
  const [myPickData, setMyPickData] = useState<any>([]);
  const [myPicsLoading, setMyPicsLoading] = useState<any>(false);

  // Market Movers

  const [marketMoversLoading, setMarketMoversLoading] = useState<any>(false);
  const [marketMoversType, setMarketMoversType] = useState<any>("gainers");
  const [marketMovers, setMarketMovers] = useState<any>([]);
  const [marketMoversData, setMarketMoversData] = useState<any>([]);
  const [marketsignalselect, setmarketsignalselect] = useState<any>("bullish");
  const [marketSignalCategories, setMarketSignalCategories] = useState<any>("bullish");
  const [myPicsType, setMyPicsType] = useState<any>("STOCK");
  const [myPicsPreference, setMyPicsPreference] = useState<any>("BUY");
  const [marketMoversFilter, setMarketMoversFilter] = useState<String>("L");
  const [marketSignalOpt, setMarketsignalOpt] = useState<any>("");
  const [myPicsOpt, setMyPicsOpt] = useState<String>("Live");
  const [boosterLoanRepaymentData, setBoosterLoanRepaymentData] = useState<any>([]);
  const [boosterLoanLoading, setBoosterLoanLoading] = useState<any>(false);

  const scrollRef2 = useRef<HTMLDivElement>(null);
  const scrollRef3 = useRef<HTMLDivElement>(null);
  const scrollRef4 = useRef<HTMLDivElement>(null);
  const scrollRef5 = useRef<HTMLDivElement>(null);
  const scrollRef6 = useRef<HTMLDivElement>(null);
  const scrollRef7 = useRef<HTMLDivElement>(null);
  // Market Signals

  const [leftarrow2, setleftarrow2] = useState<any>(false);
  const [leftarrow3, setleftarrow3] = useState<any>(false);
  const [leftarrow4, setleftarrow4] = useState<any>(false);
  const [leftarrow5, setleftarrow5] = useState<any>(false);
  const [leftarrow6, setleftarrow6] = useState<any>(false);
  const [leftarrow7, setleftarrow7] = useState<any>(false);
  const [marketSignalFilers, setMarketSignalFilers] = useState<any>([]);
  const [marketSignalFilersData, setMarketSignalFilersData] = useState<any>([]);
  const [marketSignalsLoading, setMarketSignalsLoading] = useState<any>(false);

  // Trade Ideas
  const [tradeIdeas, setTradeIdeas] = useState<any>({});
  const [tradeIdeaLoading, setTradeIdeaLoading] = useState<any>(false);
  const [chartLoading, setChartLoading] = useState<any>(false);
  const [tradeIdeaBullishChartData, setTradeIdeaBullishChartData] = useState<any>([])
  const [tradeIdeasBearishChartData, setTradeIdeasBearishChartData] = useState<any>([])

  // Market Trackers
  const [marketTrackers, setMarketTrackers] = useState<any>({});
  const [marketTrackersLoading, setMarketTrackersLoading] = useState<any>(false);


  const getSelectedAiPicksData = async (
    allStockData,
    categoryKey: string,
    actionKey: string
  ) => {
    if (allStockData?.[categoryKey]?.[actionKey]) {
      const list = allStockData[categoryKey][actionKey].map((stock: any) => {
        const item = stock.stockdata;
        const v1 = item.regularMarketPrice ?? 0;
        const v2 = item.Last ?? 0;
        const diffrence = v2 - v1;
        const div = diffrence / v1;
        const res = div * 100;
        const itemDetail = {
          displayName: item.displayName,
          stockPrice: item.Last,
          res: res,
          diffrence: diffrence,
          branding: item.branding,
          symbol: item.symbol,
          icon: item.branding?.logo_url ?? item.branding?.icon_url,
          Change: item.Change,
          ChangePercentage: item.ChangePercentage,
        };

        return itemDetail;
      });
      setAiPicksList(list);
    }
  };
  const handleScrollLeft = () => {
    if (scrollRef2.current) {
      scrollRef2.current.scrollBy({
        left: 300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
      setleftarrow2(true)
    }
  };
  const handleScrollLeft3 = () => {
    if (scrollRef3.current) {
      scrollRef3.current.scrollBy({
        left: 300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
    setleftarrow3(true)

  };
  const handleScrollLeft4 = () => {
    if (scrollRef4.current) {
      scrollRef4.current.scrollBy({
        left: 300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
    setleftarrow4(true)

  };
  const handleScrollLeft5 = () => {
    if (scrollRef5.current) {
      scrollRef5.current.scrollBy({
        left: 300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
    setleftarrow5(true)

  };
  const handleScrollLeft6 = () => {
    if (scrollRef6.current) {
      scrollRef6.current.scrollBy({
        left: 300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
    setleftarrow6(true)

  };
  const handleScrollLeft7 = () => {
    if (scrollRef7.current) {
      scrollRef7.current.scrollBy({
        left: 300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
    setleftarrow7(true)

  };
  const handleScrollRight = () => {
    if (scrollRef2.current) {
      scrollRef2.current.scrollBy({
        left: -300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };
  const handleScrollRight2 = () => {
    if (scrollRef3.current) {
      scrollRef3.current.scrollBy({
        left: -300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };
  const handleScrollRight3 = () => {
    if (scrollRef4.current) {
      scrollRef4.current.scrollBy({
        left: -300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };
  const handleScrollRight4 = () => {
    if (scrollRef5.current) {
      scrollRef5.current.scrollBy({
        left: -300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };
  const handleScrollRight5 = () => {
    if (scrollRef6.current) {
      scrollRef6.current.scrollBy({
        left: -300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };
  const handleScrollRight6 = () => {
    if (scrollRef7.current) {
      scrollRef7.current.scrollBy({
        left: -300, // Amount to scroll
        behavior: "smooth", // Enables smooth scrolling
      });
    }
  };
  const handleAiPickBuyOrSell = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setBuyOrSell(value);
    getSelectedAiPicksData(allAiPickData, selectedCategory.toString(), value);
  };
  const handleStockOrETF = (event: SelectChangeEvent) => {
    setStockOrETF(event.target.value as string);
  };
  const handleSelectedCategory = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: string
  ) => {
    setSelectedCategory(newCategory);
    if (newCategory === Constant.FOLLOWING) {
      getFollowiList();
    } else {
      getAiPickList(newCategory);
    }
  };

  const handleChangeMarketMovers = (event: SelectChangeEvent) => {
    setMarketMoversType(event.target.value as string);
  };

  const handleChangeMarketSingnalsSelect = (event: SelectChangeEvent) => {
    setmarketsignalselect(event.target.value as string);
    const data = marketSignalFilers.find((item) => item.signal === event.target.value);
    setMarketSignalCategories(data)
    setMarketsignalOpt(data.categories[0].name);
  };
  const handleChangemypicks1 = (event: SelectChangeEvent) => {
    setMyPicsType(event.target.value as string);
  };
  const handleChangeMyPicksPreference = (event: SelectChangeEvent) => {
    setMyPicsPreference(event.target.value as string);
    const data =
      allMyPickData.length &&
      allMyPickData.filter(
        (item: any) => item.recommedationType === event.target.value as string
      );
    setMyPickData(data ? data : []);
  };

  const handleChangeMarketMoversFilter = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMarketMoversFilter(newAlignment);
    const filteredData =
      marketMovers?.length &&
      marketMovers.filter((item) => item.key === newAlignment);
    setMarketMoversData(filteredData?.[0]?.data ?? []);

  };
  const onMarketSignalFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMarketsignalOpt(newAlignment);
  };
  const handleChangeMyPicksFilter = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setMyPicsOpt(newAlignment);
  };

  const getSelectedData = async (
    allStockData,
    categoryKey: string,
    actionKey: string
  ) => {
    if (allStockData?.[categoryKey]?.[actionKey]) {
      const list = allStockData[categoryKey][actionKey].map((stock: any) => {
        const item = stock.stockdata;
        const v1 = item.regularMarketPrice ?? 0;
        const v2 = item.Last ?? 0;
        const diffrence = v2 - v1;
        const div = diffrence / v1;
        const res = div * 100;
        const itemDetail = {
          displayName: item.displayName,
          stockPrice: item.Last,
          res: res,
          diffrence: diffrence,
          branding: item.branding,
          symbol: item.symbol,
          icon: item.branding?.logo_url ?? item.branding?.icon_url,
          Change: item.Change,
          ChangePercentage: item.ChangePercentage,
        };

        return itemDetail;
      });
      setAiPicksList(list);
    }
  };

  const getAiPickList = async (category) => {
    dispatch(startLoading());
    setAiPicksLoading(true);
    try {
      const response = await Axios.get(
        `${UrlConstant.AiPicks.GetAiPicks}?type=${stockOrETF}`
      );
      const resData = response.data;
      if (resData.result === true) {
        setAllAiPickData(resData.data);
        getSelectedData(resData.data, category, buyOrSell.toString());
      }
    } catch (error: any) {
      console.error("ERRR : ", error.response.status);
      if (error.response.status === 403) {
        dispatch(logout());
        navigate("/login");
      }
    } finally {
      dispatch(endLoading());
      setAiPicksLoading(false);
    }
  };

  const getMyPickList = async () => {
    dispatch(startLoading());
    setMyPicsLoading(true);
    let URL;

    if (myPicsOpt == "Live") {
      URL = UrlConstant.MyPicks.GetMyPicksLive.replace("{0}", myPicsType);
    } else {
      URL = UrlConstant.MyPicks.GetMyPicksHistory.replace("{0}", myPicsType);
    }

    try {
      const response = await Axios.get(URL);
      const resData = response.data;
      if (resData.result === true) {
        setAllMyPickData(resData.data);
        const data =
          resData.data.length &&
          resData.data.filter(
            (item: any) => item.recommedationType === myPicsPreference
          );
        setMyPickData(data ? data : []);
      }
      setMyPicsLoading(false);
    } catch (error: any) {
      console.error("ERRR : ", error.response.status);
      if (error.response.status === 403) {
        dispatch(logout());
        navigate("/login");
      }
      setMyPicsLoading(false);
    } finally {
      dispatch(endLoading());

    }
  };

  const getWatchList = async () => {
    dispatch(startLoading());
    setWatchlistLoading(true);
    try {
      const response = await Axios.get(UrlConstant.WachList.GetWatchList);
      const resData = response.data;
      if (resData.result === true) {
        const list = resData.data.map((item) => {
          const v1 = item.regularMarketPrice ?? 0;
          const v2 = item.Last ?? 0;
          const diffrence = v2 - v1;
          const div = diffrence / v1;
          const res = div * 100;
          const itemDetail = {
            displayName: item.displayName,
            stockPrice: item.Last,
            res: res,
            diffrence: diffrence,
            branding: item.branding,
            symbol: item.symbol,
            icon: item.branding?.logo_url ?? item.branding?.icon_url,
            changeAmount: item.changeAmount,
            changePercentage: item.changePercentage,
          };

          return itemDetail;
        });
        setWatchList(list);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
      setWatchlistLoading(false);
    }
  };

  const getBoosterLoanRepayment = async () => {
    setBoosterLoanLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.BoosterLoan.GetBoosterLoanRepayment
      );
      const resData = response.data;
      if (resData.status === true) {
        setBoosterLoanRepaymentData(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setBoosterLoanLoading(false);
    }
  };

  const getFollowiList = async () => {
    dispatch(startLoading());

    try {
      const response = await Axios.post(
        UrlConstant.AiPicks.GetAiPicksOfFollowing.replace("{0}", stockOrETF)
      );
      const resData = response.data;
      if (resData.result === true) {
        setAllAiPickData(resData.data);
        const list = resData.data[buyOrSell].map((stock: any) => {
          const item = stock.stockdata;
          const v1 = item.regularMarketPrice ?? 0;
          const v2 = item.Last ?? 0;
          const diffrence = v2 - v1;
          const div = diffrence / v1;
          const res = div * 100;
          const itemDetail = {
            displayName: item.displayName,
            stockPrice: item.Last,
            res: res,
            diffrence: diffrence,
            branding: item.branding,
            symbol: item.symbol,
            icon: item.branding?.logo_url ?? item.branding?.icon_url,
          };

          return itemDetail;
        });
        setAiPicksList(list);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
    }
  };
  // const checkAccountStatus = async () => {
  //   try {
  //     const response = await Axios.get(
  //       UrlConstant.TradingAccount.CheckAccountStatus
  //     );
  //     const resData = response.data;
  //     console.log("AC Status : ", resData);
  //     if (resData.status === true) {
  //       setApplicationStatus(resData.data.status);
  //       setDocumentRequired(resData.data.isDocumentUploadRequired);
  //       setApplicationId(resData.data.applicationId);
  //       if (
  //         resData.data.status !== "" &&
  //         resData.data.isDocumentUploadRequired === false &&
  //         applicationId &&
  //         applicationId !== ""
  //       ) {
  //         setDocumentSubmitted(true);
  //       }
  //     }
  //   } catch (error: any) {
  //     console.error("ERRR : ", error);
  //   }
  // };
  const getMarketMovers = async () => {
    setMarketMoversLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.MarketMovers.GetMarketMovers.replace(
          "{0}",
          marketMoversType
        )
      );
      const resData = response.data;
      setMarketMovers(resData.data);
      const order = ["L", "M", "S"];
      resData.data.sort((a, b) => {
        return order.indexOf(a.key) - order.indexOf(b.key);
      });

      resData.data?.some((item) => {
        if (item.data.length !== 0) {
          setMarketMoversFilter(item.key);
          setMarketMoversData(resData.data?.[0]?.data ?? []);
          return true;
        }
      });
      setMarketMoversLoading(false);
    } catch (error: any) {
      console.error("ERRR : ", error);
      setMarketMoversLoading(false);
    } finally {

    }
  };
  const getMarketSignalsFilters = async () => {
    setMarketSignalsLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.Market.GetMarketSignalsFilters
      );
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilers(resData.data);
        setMarketsignalOpt(resData.data[marketsignalselect]?.[0].key);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  };

  const getMarketSignalsCategories = async () => {
    setMarketSignalsLoading(true);
    try {
      const response = await Axios.get(
        UrlConstant.Market.GetMArketSignalCategories
      );
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilers(resData.data);
        setmarketsignalselect(resData.data[0].signal);
        setMarketSignalCategories(resData.data[0]);
        setMarketsignalOpt(resData.data[0].categories[0].name);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
    }
  }

  const getMarketSignalsFilterData = async () => {
    setMarketSignalsLoading(true);

    try {
      const response = await Axios.get(
        UrlConstant.Market.GetMarketSignalByCategory.replace("{0}", marketsignalselect).replace("{1}", marketSignalOpt))
      const resData = response.data;
      if (resData.status === true) {
        setMarketSignalFilersData(resData.data ?? []);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      setMarketSignalsLoading(false);
    }
  };

  // get Trade Ideas
  const getTradeIdeas = async () => {
    dispatch(startLoading());
    setTradeIdeaLoading(true);
    try {
      const response = await Axios.get(UrlConstant.TradeIdea.getTradeIdea);
      const resData = response.data;
      if (resData.status === true) {
        setTradeIdeas(resData.data);
      }
      setTradeIdeaLoading(false);
    } catch (error: any) {
      console.error("ERRR : ", error);
      setTradeIdeaLoading(false);
    } finally {
      dispatch(endLoading());
    }
  }

  // getMarketTraders
  const getMarketTrackers = async () => {
    dispatch(startLoading());
    setMarketTrackersLoading(true);
    try {
      const response = await Axios.get(UrlConstant.MarketTrackers.getMarketTrackers);
      const resData = response.data;
      if (resData.status === true) {
        setMarketTrackers(resData.data);
      }
      setMarketTrackersLoading(false);
    } catch (error: any) {
      console.error("ERRR : ", error);
      setMarketTrackersLoading(false);
    } finally {
      dispatch(endLoading());
    }
  }

  React.useEffect(() => {
    if (selectedCategory === Constant.FOLLOWING) {
      getFollowiList();
    } else {
      getAiPickList(selectedCategory);
    }
  }, [selectedCategory, stockOrETF]);

  useEffect(() => {
    getWatchList();
    getTradeIdeas();
    getMarketTrackers()
    getMarketSignalsCategories();
    getBoosterLoanRepayment();
  }, []);

  useEffect(() => {
    if (marketSignalOpt) {
      getMarketSignalsFilterData();
    }
  }, [marketSignalOpt]);

  useEffect(() => {
    getMyPickList();
  }, [myPicsOpt, myPicsType]);

  useEffect(() => {
    getMarketMovers();
  }, [marketMoversType]);

  // useEffect(() => {
  //   const filteredData =
  //     marketMovers?.length &&
  //     marketMovers.filter((item) => item.key === marketMoversFilter);
  //   setMarketMoversData(filteredData?.[0]?.data ?? []);
  // }, [marketMoversFilter, marketMovers]);

  // useEffect(() => {
  //   const data =
  //     allMyPickData.length &&
  //     allMyPickData.filter(
  //       (item: any) => item.recommedationType === myPicsPreference
  //     );
  //   setMyPickData(data ? data : []);
  // }, [allMyPickData, myPicsPreference]);


  const getLogoUrl = (symbol) => {
    if (!symbol) {
      throw new Error("Symbol is required");
    }
    return `https://logos-api.extend.apexclearing.com/logos/${symbol
      .toLowerCase()
      .trim()}.png?api_key=4RJeGywzAE&api_secret=isUE1ZzPNi5IzXbs9Qg0S01eaFUcxX09c4U88R9JBiJgJ6WxXHrXz3VtEima`;
  };

  const getUrlRange = (selection): string => {
    const now = new Date(new Date().setDate(new Date().getDate() - 1));
    let endPoint = "";

    const formatDate = (date: Date) =>
      date.toISOString().split("T")[0]; // Format as yyyy-MM-dd

    const adjustDate = (date: Date, adjust: { days?: number; months?: number; years?: number }) => {
      if (adjust.days) date.setDate(date.getDate() + adjust.days);
      if (adjust.months) date.setMonth(date.getMonth() + adjust.months);
      if (adjust.years) date.setFullYear(date.getFullYear() + adjust.years);
      return date;
    };
    switch (selection) {
      case "1 Day":
        endPoint = `/range/15/minute/${formatDate(adjustDate(new Date(now), { days: -1 }))}/${formatDate(now)}`;
        break;
      case "5 Day":
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { days: -8 }))}/${formatDate(now)}`;
        break;
      case "1 Month":
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { months: -1 }))}/${formatDate(now)}`;
        break;
      case "6 Month":
        endPoint = `/range/1/week/${formatDate(adjustDate(new Date(now), { months: -6 }))}/${formatDate(now)}`;
        break;
      case "1 Year":
        endPoint = `/range/1/month/${formatDate(adjustDate(new Date(now), { years: -1 }))}/${formatDate(now)}`;
        break;
      case "5 Years":
        endPoint = `/range/1/month/${formatDate(adjustDate(new Date(now), { years: -5 }))}/${formatDate(now)}`;
        break;
      case "All":
        endPoint = `/range/1/month/1980-01-01/${formatDate(now)}`;
        break;
      default:
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { days: -1 }))}/${formatDate(now)}`;
    }
    return endPoint;
  };

  const getChartData = async (stock) => {
    dispatch(startLoading());
    setChartLoading(true);
    try {
      const url = `https://api.polygon.io/v2/aggs/ticker/${stock}${getUrlRange("1 Month")}?apiKey=${BAR_CHART_KEY}&sort=asc&limit=5000`;
      const response = await axios.get(url);
      const apiResponse = response.data.results
      const chartData = await apiResponse.length && apiResponse.map(item => ({
        x: new Date(item.t),  // Convert Unix timestamp to Date
        y: item.c            // Extract closing price "c"
      }));
      setChartLoading(false);
      return chartData
    } catch (error) {
      console.error("Error fetching chart data:", error);
      setChartLoading(false);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (tradeIdeas.bullish) {
      getChartData(tradeIdeas.bullish.Ticker).then((bullishChartData) => {
        setTradeIdeaBullishChartData(bullishChartData || [])
      })
    }
    if (tradeIdeas.bearish) {
      getChartData(tradeIdeas.bearish.Ticker).then((bearishChartData) => {
        setTradeIdeasBearishChartData(bearishChartData || [])
      })
    }

  }, [tradeIdeas])





  return (
    <>
      <Container
        maxWidth={false}
        className="bg-color"
        style={{
          padding: " 20px 30px",
        }}
      >
        {/* ...................................................Market Trackers .......................................................... */}
        <Box
          display="flex"
          mb="50px"
          alignItems="center"
          height="fit-content"
          width="100%"
        >
          <Box width="100%">
            <Box
              display="flex"
              width="95%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" gap={2} alignItems="center" mb={1}>
                <Typography
                  className="text-white"
                  mb={0}
                  style={{ fontSize: "1.5rem" }}
                >
                  Market Trackers
                </Typography>


              </Box>
              {/* <Link
                  to="/mypicks"
                  style={{
                    color: "#4A7AF2",
                    textDecoration: "underline !importan",
                    textDecorationLine: "underline !important",
                  }}
                >
                  view all
                </Link> */}
            </Box>

            <Box width='100%' display='flex'>

              <Box

                display="flex"
                // justifyContent='space-around'
                gap="10px"
                marginRight="10px"
                width={"100%"}


              >
                {marketTrackersLoading
                  ? // <CircularProgress />
                  // <LoadingCards/>
                  Array.from({ length: 3 }).map((_, index) => (
                    <ThemeProvider theme={darkTheme}>
                      <Card
                        className="card"
                        sx={{ width: "450px", padding: '0px', height: 'fit-content' }}
                      >
                        <CardContent
                          className="text-white"
                          style={{
                            display: "flex",
                            alignItems: 'start',
                            justifyContent: "space-between",
                            height: "100%",
                            padding: '14px 20px'
                          }}
                        >
                          <Box>
                            <Typography className="text-white stock-label" sx={{ maxWidth: '250px' }}>
                              <Skeleton width='250px' height='20px' />
                            </Typography>
                            <Typography className="text-white" >
                              <Skeleton width='250px' height='20px' />
                            </Typography>
                          </Box>
                          <Skeleton sx={{ borderRadius: '30px' }} width='110px' height='80px' />

                        </CardContent>
                      </Card>
                    </ThemeProvider>
                  ))
                  : marketTrackers.length === 0 && (
                    <Card className="stock card">
                      <CardContent className="text-white">
                        <div
                          style={{
                            width: "100%",
                            color: "red",
                            textAlign: "center",
                          }}
                        >
                          No Data
                        </div>
                      </CardContent>
                    </Card>
                  )}
                {marketTrackers.length
                  ? marketTrackers.map((item) => {
                    return (
                      <Card
                        className="card"
                        sx={{ width: "450px", padding: '0px', height: 'fit-content' }}
                      // onClick={() => {
                      //   navigate("/stockdetail", {
                      //     state: {
                      //       symbol: item.symbol,
                      //       icon:
                      //         item.branding?.logo_url ??
                      //         item.branding?.icon_url,
                      //       title: "Market Trackers",
                      //     },
                      //   });
                      // }}
                      >
                        <CardContent
                          className="text-white"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "100%",
                            padding: '14px 20px'
                          }}
                        >
                          <Box>
                            <Typography className="text-white stock-label" sx={{ maxWidth: '250px' }}>
                              {item.stockInfo?.displayName}
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <Typography className="text-white" >
                                ${formatPrice(item.price)}
                              </Typography>
                              <Typography sx={{ color: item.session.change == 0 ? '' : item.session.change > 0 ? '#4edf6d' : '#FF2A2A', }}
                              >
                                {/* ${item.price} */}
                                {item.session.change == 0 ? "$" : item.session.change > 0 ? "+$" : "-$"}
                                {formatPrice(Math.abs(item.session.change))}
                                ({formatPrice(item.session.change_percent)}%)
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Button
                              fullWidth
                              className="btn-blue"
                              sx={{ padding: '9px 36px', borderRadius: '50px', textTransform: 'none' }}
                              onClick={() => {
                                navigate("/stockdetail", {
                                  state: {
                                    symbol: item.ticker,
                                    icon:
                                      item.branding?.logo_url ??
                                      item.branding?.icon_url,
                                    title: "Market Trackers",
                                  },
                                });
                              }}
                            >
                              Trade
                            </Button>

                          </Box>

                          {/* </Box> */}

                        </CardContent>
                      </Card>
                    );
                  })
                  : null}
              </Box>

            </Box>
          </Box>
        </Box>
        {/* -----------------------------------------------------------------------------Trade Ideas ------------------------------------------ */}
        {!tradeIdeaLoading && !tradeIdeas?.bullish?.Ticker && !tradeIdeas?.bearish?.Ticker ? null : <>
          <Typography className="text-white" fontSize='24px' width='100%' mb='10px'>Trade Ideas</Typography>
          <Box display='flex' mb='50px' gap='10px'>
            {tradeIdeaLoading ? <LoadingMiniCharts /> :
              <>
                {
                  tradeIdeas?.bullish?.Ticker
                    ?
                    <Box width='50%'>
                      <Box width='100%' display='flex' borderRadius='20px' border='1px solid #4EDF6D' bgcolor='#4EDF6D1A' padding='20px'>
                        <Box width='calc(100% - 212px)'>
                          <Box display='flex' alignItems='start' gap='20px'>
                            <Avatar
                              sx={{
                                height: '64px',
                                width: '64px',
                                backgroundColor: "white",
                                // objectFit: "fill",
                                '& .MuiAvatar-img': {
                                  objectFit: 'contain'
                                }
                              }}
                              src={
                                tradeIdeas?.bullish?.summary.branding?.logo_url ??
                                tradeIdeas?.bullish?.summary.branding?.icon_url
                              }
                            />
                            <Box>
                              <Typography
                                fontSize='24px'
                                fontWeight='bold'
                                className="text-white stock-label"
                                maxWidth='300px'

                              >
                                {tradeIdeas?.bullish?.summary?.name}
                              </Typography>
                              <Typography fontSize='18px' className="text-secondary">NYSE: {tradeIdeas?.bullish?.Ticker}</Typography>
                              <Typography fontSize='18px' className="text-white" fontWeight='bold'>${tradeIdeas?.bullish?.summary?.price}
                                {
                                  tradeIdeas?.bullish?.snapshot.session.change > 0 ?
                                    <span style={{ color: '#4EDF6D' }}> +{tradeIdeas?.bullish?.snapshot.session.change?.toFixed(2)} ({tradeIdeas?.bullish?.snapshot.session.change_percent?.toFixed(2)}%)</span>
                                    : null}
                              </Typography>
                              <Typography fontSize="15px" className="text-white">
                                {moment(tradeIdeas?.bullish?.time_stamp).format("MMM DD, YYYY")} | <span style={{ color: '#4A7AF2' }}>
                                  {moment(tradeIdeas?.bullish?.time_stamp).format("hh:mm A")}
                                </span>
                              </Typography>

                            </Box>
                          </Box>
                          <Box padding={"5px"}>

                            <LinechartMini data={tradeIdeaBullishChartData || []} lineColor="#4EDF6D" />
                          </Box>
                        </Box>
                        <Box width='212px'>
                          <Box mb='20px'>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Previous Close</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bullish?.snapshot?.session?.previous_close?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Open</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bullish?.snapshot?.session?.open?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Bid</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bullish?.snapshot?.last_quote?.bid?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Ask</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bullish?.snapshot?.last_quote?.ask?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>High</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bullish?.snapshot?.session?.high?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Low</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bullish?.snapshot?.session?.low?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Volume</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>{tradeIdeas?.bullish?.Volume}</Typography>
                            </Box>
                          </Box>
                          <Button
                            fullWidth
                            sx={{
                              color: 'black',
                              bgcolor: '#4EDF6D',
                              fontWeight: 'bold',
                              borderRadius: '50px',
                              textTransform: 'none'
                            }}
                            onClick={() => {
                              navigate("/stockdetail", {
                                state: {
                                  symbol: tradeIdeas?.bullish?.Ticker,
                                  icon:
                                    tradeIdeas?.bullish?.summary.branding?.logo_url ??
                                    tradeIdeas?.bullish?.summary.branding?.icon_url,
                                  title: "Trade Ideas",
                                },
                              });
                            }}
                          >
                            Trade
                          </Button>

                        </Box>
                      </Box>
                    </Box>
                    : null
                }
                {
                  tradeIdeas?.bearish?.Ticker
                    ?
                    <Box width='50%'>
                      <Box width='100%' display='flex' borderRadius='20px' border='1px solid #FF2A2A' bgcolor='#FF2A2A1A' padding='20px'>
                        <Box width='calc(100% - 212px)'>
                          <Box display='flex' alignItems='start' gap='20px'>
                            <Avatar
                              sx={{
                                height: '64px',
                                width: '64px',
                                backgroundColor: "white",
                                '& .MuiAvatar-img': {
                                  objectFit: 'contain'
                                }

                              }}
                              src={
                                tradeIdeas?.bearish?.summary.branding?.logo_url ??
                                tradeIdeas?.bearish?.summary.branding?.icon_url
                              }
                            />
                            <Box>
                              <Typography
                                fontSize='24px'
                                fontWeight='bold'
                                className="text-white stock-label"
                                maxWidth='300px'
                              >
                                {tradeIdeas?.bearish?.summary?.name}
                              </Typography>
                              <Typography fontSize='18px' className="text-secondary">NYSE: {tradeIdeas?.bearish?.Ticker}</Typography>
                              <Typography fontSize='18px' className="text-white" fontWeight='bold'>${tradeIdeas?.bearish?.summary?.price}
                                {
                                  tradeIdeas?.bearish?.snapshot.session.change < 0 ?
                                    <span style={{ color: "#FF2A2A" }}> {tradeIdeas?.bearish?.snapshot.session.change?.toFixed(2)} ({tradeIdeas?.bearish?.snapshot.session.change_percent?.toFixed(2)}%)</span>
                                    : null
                                }
                              </Typography>
                              <Typography fontSize="15px" className="text-white">
                                {moment(tradeIdeas?.bearish?.time_stamp).format("MMM DD, YYYY")} | <span style={{ color: '#4A7AF2' }}>
                                  {moment(tradeIdeas?.bearish?.time_stamp).format("hh:mm A")}
                                </span>
                              </Typography>

                            </Box>
                          </Box>
                          <Box padding={"5px"}>
                            <LinechartMini data={tradeIdeasBearishChartData || []} lineColor="#FF2A2A" />
                          </Box>
                        </Box>
                        <Box width='212px'>
                          <Box mb='20px'>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Previous Close</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bearish?.snapshot?.session?.previous_close?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Open</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bearish?.snapshot?.session?.open?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Bid</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bearish?.snapshot?.last_quote?.bid?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Ask</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bearish?.snapshot?.last_quote?.ask?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>High</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bearish?.snapshot?.session?.high?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Low</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>${tradeIdeas?.bearish?.snapshot?.session?.low?.toFixed(2)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                              <Typography fontSize='16px' className='text-white'>Volume</Typography>
                              <Typography fontSize='20px' className='text-white' fontWeight='bold'>{tradeIdeas?.bearish?.Volume}</Typography>
                            </Box>
                          </Box>
                          <Button
                            fullWidth
                            sx={{
                              color: 'black',
                              bgcolor: '#4EDF6D',
                              fontWeight: 'bold',
                              borderRadius: '50px',
                              textTransform: 'none'
                            }}
                            onClick={() => {
                              navigate("/stockdetail", {
                                state: {
                                  symbol: tradeIdeas?.bearish?.Ticker,
                                  icon:
                                    tradeIdeas?.bearish?.summary.branding?.logo_url ??
                                    tradeIdeas?.bearish?.summary.branding?.icon_url,
                                  title: "Trade Ideas",
                                },
                              });
                            }}
                          >
                            Trade
                          </Button>

                        </Box>
                      </Box>
                    </Box>
                    : null
                }


              </>
            }

          </Box>
        </>}

        <Box style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
          {/* ....................................... Left Side Content ........................................ */}
          <Box sx={{ width: "64%", left: "0", margin: "0" }}>
            {/* .......................................... Indices.................................................... */}
            {/* <Box display="flex">
            <Box mb='50px' width="100%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  mb={2}
                  style={{ fontSize: "1.5rem", color: "#F2674A" }}
                >
                  {" "}
                  Indices{" "}
                </Typography>
                <Link
                  to="/"
                  style={{
                    color: "#4A7AF2",
                    textDecoration: "underline !importan",
                    textDecorationLine: "underline !important",
                  }}
                >
                  view all
                </Link>
              </Box>
              <Box>
                <Card className="card">
                  <CardContent className="text-white">
                    <Typography variant="body2" gutterBottom>
                      NIFTY
                    </Typography>
                    <Typography variant="body2" mb="0px" mr={10}>
                      $ 27,794.40{" "}
                      <span style={{ color: "#ff2a2a" }}>-1.35(0.01%)</span>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Box>
            <IconButton sx={{ pl: "0px", marginTop: "50px" }}>
              <ExpandCircleDownOutlined
                sx={{ rotate: "-90deg" }}
              ></ExpandCircleDownOutlined>
            </IconButton>
          </Box> */}
            {/* ----------------------------------------------------------Trade Idea ------------------------------------------- */}

            {/* .............................................. Watch List.......................................................... */}
            <Box
              display="flex"
              mb="50px"
              alignItems="center"
              height="fit-content"
              width="100%"
            >
              <Box width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width='95%'
                >
                  <Typography
                    className="text-white"
                    mb={2}
                    style={{ fontSize: "1.5rem" }}
                  >
                    Watchlist
                  </Typography>
                  <NavLink
                    to="/wachlist"
                    style={{
                      color: "#4A7AF2",
                      textDecoration: "underline !importan",
                      textDecorationLine: "underline !important",
                    }}
                  >
                    view all
                  </NavLink>
                </Box>
                <Box width='100%' display='flex'>
                  <IconButton
                    sx={{ width: "5%", borderRadius: "0px" }}
                    onClick={handleScrollRight5}
                  >
                    <ExpandCircleDownOutlined
                      className="text-secondary"
                      sx={{ rotate: "-270deg", fill: leftarrow6 && 'white' }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                  <Box
                    display="flex"
                    gap="10px"
                    marginRight="10px"
                    ref={scrollRef6}
                    // justifyContent='space-around'
                    width={"100%"}

                    overflow="auto"
                    sx={{
                      "&::-webkit-scrollbar": {
                        height: "6px", // Adjust scrollbar height
                        // marginTop:'10px !important'
                        display: "none",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(255,255,255,0.1)", // Scrollbar thumb color
                        borderRadius: "4px", // Rounded scrollbar thumb
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "", // Thumb color on hover
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent", // Scrollbar track color
                      },
                    }}
                  >
                    {watchList.length > 0 ? (
                      watchList.slice(0, 7).map((item: any, index: any) => (
                        <Card
                          className="stock card dcard-hover"
                          key={index}
                          onClick={() => {
                            navigate("/stockdetail", {
                              state: {
                                symbol: item.symbol,
                                icon: item.branding?.icon_url ?? item.branding?.logo_url,
                                title: "Watchlist",
                              },
                            });
                          }}
                        >
                          <CardContent className="text-white">
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="10px"
                              mb={1}
                            >
                              {item.branding?.icon_url || item.branding?.logo_url ? (
                                <Avatar
                                  className="stock-icon"
                                  alt={item.symbol}
                                  src={item.branding?.icon_url ?? item.branding?.logo_url}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    backgroundColor: "white",
                                    objectFit: "fill",
                                  }}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#f0f0f0",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: "#212529",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item.symbol}
                                  </Typography>
                                </Box>
                              )}
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                className="stock-label"
                                mb={0}
                              >
                                {item.displayName}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body1"
                              textAlign="left"
                              fontWeight={600}
                              mb="0px"
                            >
                              ${formatPrice(item.stockPrice)}
                            </Typography>
                            <Typography
                              variant="caption"
                              textAlign="left"
                              mr={2}
                              sx={{
                                color: item.changeAmount >= 0 ? "#4edf6d" : "red",
                              }}
                            >
                              {item.changeAmount == 0 ? "$" : item.changeAmount > 0 ? "+$" : "-$"}
                              {formatPrice(Math.abs(item.changeAmount))}
                              ({formatPrice(item.changePercentage)}%)
                            </Typography>
                          </CardContent>
                        </Card>
                      ))
                    ) : watchlistLoading ? (
                      // <CircularProgress />
                      <LoadingCards />
                    ) : (
                      <Watchlistcard />
                    )}
                  </Box>
                  <IconButton
                    onClick={handleScrollLeft6}
                    sx={{ width: "5%", borderRadius: "0px" }}
                  >
                    <ExpandCircleDownOutlined
                      sx={{ rotate: "-90deg" }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                </Box>
              </Box>
            </Box>

            {/* ............................................ Market Movers ................................................... */}
            <Box
              display="flex"
              mb="50px"
              alignItems="center"
              height="fit-content"
              width="100%"
            >
              <Box width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width='95%'
                >
                  <Box display="flex" gap={2} alignItems="center" mb={1}>
                    <Typography
                      className="text-white"
                      mb={0}
                      style={{ fontSize: "1.5rem" }}
                    >
                      {" "}
                      Market Movers
                    </Typography>

                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={marketMoversType}
                        className="text-white"
                        sx={{
                          // color:'!important',
                          borderRadius: "9px !important",
                          borderColor: "red !important",
                          padding: "0px !important",
                          ".MuiSvgIcon-root": {
                            color: "white !important",
                          },
                        }}
                        onChange={handleChangeMarketMovers}
                      >
                        <MenuItem value="gainers">Gainers</MenuItem>
                        <MenuItem value="losers">Losers</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <NavLink
                    to="/marketMovers"
                    style={{
                      color: "#4A7AF2",
                      textDecoration: "underline !importan",
                      textDecorationLine: "underline !important",
                    }}
                  >
                    view all
                  </NavLink>
                </Box>

                <Box mb={1}>
                  <ToggleButtonGroup
                    // className="border-white"
                    // color="primary"
                    value={marketMoversFilter}
                    exclusive
                    onChange={(event, newFilter) => {
                      if (newFilter !== null) {
                        handleChangeMarketMoversFilter(event, newFilter);
                      }
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton value="L" sx={{ textTransform: "none" }}>
                      Large
                    </ToggleButton>
                    <ToggleButton value="M" sx={{ textTransform: "none" }}>
                      Mid
                    </ToggleButton>
                    <ToggleButton value="S" sx={{ textTransform: "none" }}>
                      Small
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box width='100%' display='flex'>
                  <IconButton
                    sx={{ width: "5%", borderRadius: "0px" }}
                    onClick={handleScrollRight}
                  >
                    <ExpandCircleDownOutlined
                      className="text-secondary"
                      sx={{ rotate: "-270deg", fill: leftarrow2 && 'white' }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                  <Box
                    width="100%"
                    overflow="hidden"
                    ref={scrollRef2}
                    display="flex"
                    gap={"10px"}
                  >
                    {marketMoversLoading ? (
                      // <CircularProgress />
                      <LoadingCards />
                    ) : (
                      marketMoversData?.length === 0 && (
                        <Card className="stock card">
                          <CardContent className="text-white">
                            <div
                              style={{
                                width: "100%",
                                color: "red",
                                textAlign: "center",
                              }}
                            >
                              No Data
                            </div>
                          </CardContent>
                        </Card>
                      )
                    )}
                    {marketMoversData?.length
                      ? marketMoversData.map((item) => {
                        return (
                          <Card
                            className="stock card dcard-hover"
                            onClick={() => {
                              navigate("/stockdetail", {
                                state: {
                                  symbol: item.ticker,
                                  icon: item.branding?.icon_url ?? item.branding?.logo_url,
                                  title: "Market Movers",
                                },
                              });
                            }}
                          >
                            <CardContent className="text-white">
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="10px"
                                mb={1}
                              >
                                <Avatar
                                  alt={item.ticker}
                                  src={item.branding?.icon_url ?? item.branding?.logo_url}
                                  sx={{ width: 30, height: 30 }}
                                />
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  className="stock-label"
                                  mb={0}
                                >
                                  {item?.company_name}
                                </Typography>
                              </Box>
                              <Typography
                                variant="body1"
                                fontWeight={600}
                                mb="0px"
                              >
                                ${formatPrice(item.price)}
                              </Typography>

                              <Typography
                                variant="caption"
                                mr={2}
                                sx={{ color: item.change == 0 ? '' : item.change > 0 ? '#4edf6d' : 'red', }}
                              >

                                {item.change == 0 ? "$" : item.change > 0 ? "+$" : "-$"}
                                {formatPrice(Math.abs(item.change))}
                                ({formatPrice(item.change_percent)}%)
                              </Typography>
                            </CardContent>
                          </Card>
                        );
                      })
                      : null}

                  </Box>
                  <IconButton
                    sx={{ width: "5%", borderRadius: "0px" }}
                    onClick={handleScrollLeft}
                  >
                    <ExpandCircleDownOutlined
                      sx={{ rotate: "-90deg" }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                </Box>

              </Box>
            </Box>
            {/* ................................................ Market Signals ................................................... */}
            <Box
              display="flex"
              mb="50px"
              alignItems="center"
              height="fit-content"
              width="100%"
            >
              <Box width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width='95%'
                >
                  <Box display="flex" gap={2} alignItems="center" mb={1}>
                    <Typography
                      className="text-white"
                      mb={0}
                      style={{ fontSize: "1.5rem" }}
                    >
                      {" "}
                      Market Signals
                    </Typography>

                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select2"
                        value={marketsignalselect}
                        className="text-white"
                        sx={{
                          // color:'!important',
                          borderRadius: "9px !important",
                          padding: "0px !important",
                          ".MuiSvgIcon-root": {
                            color: "white !important",
                          },
                        }}
                        onChange={handleChangeMarketSingnalsSelect}
                      >
                        {marketSignalFilers.map((item) => {

                          return (
                            <MenuItem value={item.signal} selected>
                              {item.signal}
                            </MenuItem>
                          )
                        })
                        }

                      </Select>
                    </FormControl>
                  </Box>
                  <Link
                    to="/marketSignals"
                    style={{
                      color: "#4A7AF2",
                      textDecoration: "underline !importan",
                      textDecorationLine: "underline !important",
                    }}
                  >
                    view all
                  </Link>
                </Box>

                <Box mb={1} overflow='auto' maxWidth='94%' sx={{
                  scrollbarWidth: "none", // For Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // For Chrome, Safari, and Edge
                  },
                }}>
                  <ToggleButtonGroup
                    // className="border-white"
                    // color="primary"
                    value={marketSignalOpt}
                    exclusive
                    onChange={(event, newFilter) => {
                      if (newFilter !== null) {
                        if (marketSignalCategories?.categories?.findIndex((item) => item.name === newFilter) > 0 && !isPremium) {
                          showPremiumAlert(navigate)
                        } else {
                          onMarketSignalFilterChange(event, newFilter);
                        }
                      }
                    }}
                    aria-label="Platform"
                  >
                    {marketSignalCategories?.categories?.map((filter, i) => {
                      return (
                        <ToggleButton
                          value={filter.name}
                          sx={{ textTransform: "none" }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"

                          >
                            <Avatar
                              alt={filter?.name}
                              src={
                                marketSignalOpt == filter.name
                                  ? filter?.selectedIconUrl
                                  : filter?.iconUrl
                              }
                              sx={{ width: 16, height: 16, marginRight: "5px" }}
                            />
                            <Typography sx={{ textWrap: 'nowrap' }}>{filter.name || <Skeleton></Skeleton>}</Typography>
                          </Box>
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </Box>
                <Box display='flex' width='100%'>
                  <IconButton
                    sx={{ width: "5%", borderRadius: "0px" }}
                    onClick={handleScrollRight2}
                  >
                    <ExpandCircleDownOutlined
                      className="text-secondary"
                      sx={{ rotate: "-270deg", fill: leftarrow3 && 'white' }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                  <Box
                    display="flex"
                    gap={"10px"}
                    marginRight="10px"
                    overflow="auto"
                    ref={scrollRef3}
                    width={"100%"}
                    sx={{
                      scrollbarWidth: "none", // For Firefox
                      "&::-webkit-scrollbar": {
                        display: "none", // For Chrome, Safari, and Edge
                      },
                    }}
                  >
                    {marketSignalsLoading ? (
                      <LoadingCards />
                    ) : (
                      marketSignalFilersData.length === 0 && (
                        <Card className="stock card">
                          <CardContent className="text-white">
                            <Box
                              sx={{
                                width: "100%",
                                color: "red",
                                textAlign: "center",
                              }}
                            >
                              No Data
                            </Box>
                          </CardContent>
                        </Card>
                      )
                    )}
                    {marketSignalFilersData?.length
                      ? marketSignalFilersData.slice(0, 10).map((item) => {
                        return (
                          <Card
                            className="stock card  dcard-hover"
                            onClick={() => {
                              navigate("/stockdetail", {
                                state: {
                                  symbol: item.ticker,
                                  icon: item.branding?.icon_url ?? item.branding?.logo_url,
                                  title: "Market Signals",
                                },
                              });
                            }}
                          >
                            <CardContent className="text-white">
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="10px"
                                mb={1}
                              >
                                <Avatar
                                  alt={item.ticker}
                                  src={item.branding?.icon_url ?? item.branding?.logo_url}
                                  sx={{ width: 30, height: 30 }}
                                />
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  className="stock-label"
                                  mb={0}
                                >
                                  {item.company}
                                </Typography>
                              </Box>
                              <Typography
                                variant="body1"
                                fontWeight={600}
                                mb="0px"
                              >
                                ${formatPrice(item.price)}
                              </Typography>
                              <Typography
                                variant="caption"
                                mr={2}
                                sx={{ color: item.change == 0 ? '' : item.change > 0 ? '#4edf6d' : 'red', }}
                              >

                                {item.change == 0 ? "$" : item.change > 0 ? "+$" : "-$"}
                                {formatPrice(Math.abs(item.change))}
                                ({formatPrice(item.change_percent)}%)
                              </Typography>
                            </CardContent>
                          </Card>
                        );
                      })
                      : null}
                  </Box>
                  <IconButton
                    sx={{ width: "5%", zIndex: "99", borderRadius: "0px" }}
                    onClick={() => {
                      handleScrollLeft3();
                    }}
                  >
                    <ExpandCircleDownOutlined
                      sx={{ rotate: "-90deg" }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                </Box>
              </Box>

            </Box>
            {/* ...................................................My Picks .......................................................... */}
            <Box
              display="flex"
              mb="50px"
              alignItems="center"
              height="fit-content"
              width="100%"
            >
              <Box width="100%">
                <Box
                  display="flex"
                  width="95%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap={2} alignItems="center" mb={1}>
                    <Typography
                      className="text-white"
                      mb={0}
                      style={{ fontSize: "1.5rem" }}
                    >
                      My Picks
                    </Typography>
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select2"
                        value={myPicsType}
                        className="text-white"
                        sx={{
                          // color:'!important',
                          borderRadius: "9px !important",
                          padding: "0px !important",
                          ".MuiSvgIcon-root": {
                            color: "white !important",
                          },
                        }}
                        onChange={handleChangemypicks1}
                      >
                        <MenuItem value="STOCK">Stock</MenuItem>
                        <MenuItem value="ETF" selected>
                          ETF
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select2"
                        value={myPicsPreference}
                        className="text-white"
                        sx={{
                          // color:'!important',
                          borderRadius: "9px !important",
                          padding: "0px !important",
                          ".MuiSvgIcon-root": {
                            color: "white !important",
                          },
                        }}
                        onChange={handleChangeMyPicksPreference}
                      >
                        <MenuItem value="BUY">Buy</MenuItem>
                        <MenuItem value="SELL" selected>
                          Sell
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Link
                    to="/mypicks"
                    style={{
                      color: "#4A7AF2",
                      textDecoration: "underline !importan",
                      textDecorationLine: "underline !important",
                    }}
                  >
                    view all
                  </Link>
                </Box>
                <Box mb={1}>
                  <ToggleButtonGroup
                    // className="border-white"
                    // color="primary"
                    value={myPicsOpt}
                    exclusive
                    onChange={(event, newFilter) => {
                      if (newFilter !== null) {
                        handleChangeMyPicksFilter(event, newFilter);
                      }
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Live" sx={{ textTransform: "none" }}>
                      Live
                    </ToggleButton>
                    <ToggleButton value="History" sx={{ textTransform: "none" }}>
                      History
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box width='100%' display='flex'>
                  <IconButton
                    sx={{ width: "5%", borderRadius: "0px" }}
                    onClick={handleScrollRight3}
                  >
                    <ExpandCircleDownOutlined
                      className="text-secondary"
                      sx={{ rotate: "-270deg", fill: leftarrow4 && 'white' }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                  <Box
                    ref={scrollRef4}
                    display="flex"
                    // justifyContent='space-around'
                    gap="10px"
                    marginRight="10px"
                    width={"100%"}

                    overflow="auto"
                    sx={{
                      "&::-webkit-scrollbar": {
                        height: "6px", // Adjust scrollbar height
                        // marginTop:'10px !important'
                        display: "none",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(255,255,255,0.1)", // Scrollbar thumb color
                        borderRadius: "4px", // Rounded scrollbar thumb
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "", // Thumb color on hover
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent", // Scrollbar track color
                      },
                    }}
                  >
                    {myPicsLoading
                      ? // <CircularProgress />
                      // <LoadingCards/>
                      Array.from({ length: 5 }).map((_, index) => (
                        <Card
                          className="card dcard-hover"
                          key={index}
                          sx={{ minWidth: "300px" }}
                        >
                          <CardContent
                            className="text-white"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "40px",
                              width: "100%",
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="10px"
                              mb={1}
                              width="100%"
                            >
                              <Box sx={{ width: 30, height: 30 }}>
                                <Skeleton variant="circular" />
                              </Box>
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                mb={0}
                                sx={{
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "normal",
                                  fontWeight: "bold",
                                  width: "100%",
                                }}
                              >
                                <Skeleton />
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="body1"
                                fontWeight={600}
                                mb="0px"
                                textAlign="end"
                                width="100%"
                              >
                                <Skeleton />
                              </Typography>
                              <Typography
                                variant="caption"
                                mb={0}
                                style={{ color: "#ff2a2a" }}
                              >
                                <Skeleton />
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      ))
                      : myPickData.length === 0 && (
                        <Card className="stock card">
                          <CardContent className="text-white">
                            <div
                              style={{
                                width: "100%",
                                color: "red",
                                textAlign: "center",
                              }}
                            >
                              No Data
                            </div>
                          </CardContent>
                        </Card>
                      )}
                    {myPickData.length
                      ? myPickData.map((item) => {
                        return (
                          <Card
                            className="card dcard-hover"
                            sx={{ minWidth: "300px" }}
                            onClick={() => {
                              navigate("/stockdetail", {
                                state: {
                                  symbol: item.symbol,
                                  icon:
                                    item.branding?.logo_url ??
                                    item.branding?.icon_url,
                                  title: "My Picks",
                                },
                              });
                            }}
                          >
                            <CardContent
                              className="text-white"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box display="flex" alignItems="center" gap="10px" mb={1}>
                                {item.branding?.logo_url ? (
                                  <Avatar
                                    alt={item.branding?.logo_url}
                                    src={item.branding?.logo_url}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      backgroundColor: "white",
                                      objectFit: "fill",
                                    }}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: "#f0f0f0",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        color: "#212529",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item.symbol}
                                    </Typography>
                                  </Box>
                                )}
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  mb={0}
                                  sx={{
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "normal",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.displayName}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  fontWeight={600}
                                  mb="0px"
                                  textAlign="end"
                                >
                                  {item.Last == null
                                    ? `$${formatPrice(item.regularMarketPrice)}`
                                    : `$${item.Last}`}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  mb={0}
                                  style={{ color: "#ff2a2a", display: 'block' }}
                                >
                                  {item.Last == null
                                    ? `Previous close`
                                    : `Approx`}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        );
                      })
                      : null}
                  </Box>
                  <IconButton
                    onClick={handleScrollLeft4}
                    sx={{ width: "5%", borderRadius: "0px" }}
                  >
                    <ExpandCircleDownOutlined
                      sx={{ rotate: "-90deg" }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                </Box>
              </Box>
            </Box>

            {/* ............................................ AI Picks ................................................... */}
            <Box
              display="flex"
              mb="50px"
              alignItems="center"
              height="fit-content"
              width="100%"
            >
              <Box width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width='95%'
                >
                  <Box display="flex" gap={2} alignItems="center" mb={1}>
                    <Typography
                      className="text-white"
                      mb={0}
                      style={{ fontSize: "1.5rem" }}
                    >
                      AI Picks
                    </Typography>

                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={buyOrSell}
                        className="text-white"
                        sx={{
                          borderRadius: "9px !important",
                          padding: "0px !important",
                          ".MuiSvgIcon-root": {
                            color: "white !important",
                          },
                        }}
                        onChange={handleAiPickBuyOrSell}
                      >
                        <MenuItem value={Constant.BUY}>Buy</MenuItem>
                        <MenuItem value={Constant.SELL}>Sell</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stockOrETF}
                        className="text-white"
                        sx={{
                          borderRadius: "9px !important",
                          padding: "0px !important",
                          ".MuiSvgIcon-root": {
                            color: "white !important",
                          },
                        }}
                        onChange={handleStockOrETF}
                      >
                        <MenuItem value={Constant.STOCK}>Stock</MenuItem>
                        <MenuItem value={Constant.ETF}>ETF</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <NavLink
                    to="/aipicks"
                    style={{
                      color: "#4A7AF2",
                      textDecoration: "underline !important",
                      textDecorationLine: "underline !important",
                    }}
                  >
                    view all
                  </NavLink>
                </Box>

                <Box mb={1}>
                  <ToggleButtonGroup
                    value={selectedCategory}
                    exclusive
                    onChange={(event, newFilter) => {
                      if (newFilter !== null) {
                        if (Constant.POPULAR !== newFilter && !isPremium) {
                          showPremiumAlert(navigate)
                        } else {
                          handleSelectedCategory(event, newFilter);
                        }
                      }
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value={Constant.POPULAR}
                      sx={{ textTransform: "none" }}
                    >
                      Popular
                    </ToggleButton>
                    <ToggleButton
                      value={Constant.DIVIDENT_PAYING}
                      sx={{ textTransform: "none" }}
                    >
                      Dividend Paying
                    </ToggleButton>
                    <ToggleButton
                      value={Constant.GROWTH}
                      sx={{ textTransform: "none" }}
                    >
                      Growth
                    </ToggleButton>
                    <ToggleButton
                      value={Constant.FOLLOWING}
                      sx={{ textTransform: "none" }}
                    >
                      Following
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box width='100%' display='flex'>
                  <IconButton
                    sx={{ width: "5%", borderRadius: "0px" }}
                    onClick={handleScrollRight4}
                  >
                    <ExpandCircleDownOutlined
                      className="text-secondary"
                      sx={{ rotate: "-270deg", fill: leftarrow5 && 'white' }}
                    ></ExpandCircleDownOutlined >
                  </IconButton>
                  <Box
                    display="flex"
                    gap="10px !important"
                    overflow="auto"
                    ref={scrollRef5}
                    sx={{
                      "::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {aiPicksLoading ? (
                      // <CircularProgress />
                      <LoadingCards />
                    ) : (
                      aiPicksList.length === 0 && (
                        <Card className="stock card">
                          <CardContent className="text-white">
                            <div
                              style={{
                                width: "100%",
                                color: "red",
                                textAlign: "center",
                              }}
                            >
                              No Data
                            </div>
                          </CardContent>
                        </Card>
                      )
                    )}

                    {aiPicksList.map((item: any, index: any) => (
                      <IconButton
                        sx={{ padding: "0px" }}
                        onClick={() => {
                          navigate("/stockdetail", {
                            state: {
                              symbol: item.symbol,
                              icon:
                                item.branding?.logo_url ?? item.branding?.icon_url,
                              title: "AI Picks",
                            },
                          });
                        }}
                      >
                        <Card className="stock card dcard-hover" key={index}>
                          <CardContent
                            className="text-white"
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="10px"
                              mb={1}
                            >
                              {item.icon ? (
                                <Avatar
                                  className="stock-icon"
                                  alt={item.icon}
                                  src={item.icon}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    backgroundColor: "white",
                                    objectFit: "fill",
                                  }}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#f0f0f0",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <Typography
                                    // variant="h3"
                                    sx={{
                                      fontWeight: "bold",
                                      color: "#212529",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item.symbol}
                                  </Typography>
                                </Box>
                              )}

                              <Typography
                                variant="body2"
                                fontWeight={600}
                                className="stock-label"
                                mb={0}
                              >
                                {item.displayName}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body1"
                              textAlign="left"
                              fontWeight="bold"
                              mb="0px"
                            >
                              {/* $ {item.stockPrice?.toFixed(2)} */}
                              {item.stockPrice === null
                                ? "-"
                                : `$${item.stockPrice?.toFixed(2)}`}
                            </Typography>
                            <Typography
                              mr={2}
                              textAlign="left"
                              variant="caption"
                              sx={{
                                position: "relative",
                                color: item.Change >= 0 ? "#4edf6d" : "red",
                              }}
                            >
                              {item.Change > 0 ? "+$" : "-$"}
                              {Math.abs(item.Change)?.toFixed(2)}(
                              {item.ChangePercentage?.toFixed(2)}%)
                            </Typography>
                          </CardContent>
                        </Card>
                      </IconButton>
                    ))}
                    {/* <Card className="card">
                <CardContent className="text-white">
                  <Box display="flex" alignItems="center" gap="10px" mb={1}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: 30, height: 30 }}
                    />
                    <Typography mb={0}>Strike</Typography>
                  </Box>
                  <Typography mb="0px">$ 27,794.40</Typography>
                  <Typography mr={2} sx={{ color: "#4edf6d" }}>
                    -1.35(0.01%)
                  </Typography>
                </CardContent>
              </Card> */}
                  </Box>

                  <IconButton
                    onClick={handleScrollLeft5}
                    sx={{ width: "5%", borderRadius: "0px" }}
                  >
                    <ExpandCircleDownOutlined
                      sx={{ rotate: "-90deg" }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                </Box>
              </Box>
            </Box>



            {/* ..................................................... Boosterloan.................................................... */}
            {/* <Box
              display="flex"
              mb="50px"
              alignItems="center"
              height="fit-content"
              width="100%"
            >
              <Box width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  // gap="20px"
                  width='95%'
                >
                  <Typography
                    className="text-white"
                    variant="body2"
                    fontWeight={600}
                    mb={2}
                    style={{ fontSize: "1.5rem" }}
                  >
                    Booster Loan
                  </Typography>
                  <Link
                    to="/boosterloan"
                    style={{
                      color: "#4A7AF2",
                      textDecoration: "underline !importan",
                      textDecorationLine: "underline !important",
                    }}
                  >
                    details
                  </Link>
                </Box>
                <Box width='100%' display='flex'>
                  <IconButton
                    sx={{ width: "5%", borderRadius: "0px" }}
                    onClick={handleScrollRight6}
                  >
                    <ExpandCircleDownOutlined
                      className="text-secondary"
                      sx={{ rotate: "-270deg", }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                  <Box display="flex" gap="20px" width='100%' overflow='hidden'>
                    {boosterLoanLoading ? (
                      // <CircularProgress />
                      <LoadingCards />
                    ) : (
                      boosterLoanRepaymentData.length === 0 && <BoosterLoanCard />
                    )}

                    {boosterLoanRepaymentData?.map((item) => {
                      return (
                        <Card className="card dcard-hover">
                          <CardContent className="text-white">
                            <Box>
                              <Box display="flex" gap="60px" mb={2}>
                                <Box display="flex" alignItems="center" gap="10px">
                                  <Avatar
                                    src={getLogoUrl(item.symbol)}
                                    alt={item.symbol}
                                    sx={{ width: 30, height: 30 }}
                                  />
                                  <Typography
                                    variant="body2"
                                    fontWeight={600}
                                    mb={0}
                                  >
                                    {item.symbol}
                                  </Typography>
                                </Box>
                                <Button className="btn" sx={{ padding: '5px 10px', backgroundColor: '#4EDF6D33', color: '#4EDF6D', borderRadius: '30px' }}>upcoming</Button>
                              </Box>
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                mb="0px"
                              >
                                $ {item.amount?.toFixed(2)}
                              </Typography>
                              <Box display="flex" justifyContent="space-between">
                                <Box>
                                  <Typography
                                    fontSize='12px'
                                    mb="0px"
                                  >
                                    05 out of 10
                                  </Typography>
                                 
                                </Box>
                                <Box display='flex'>
                                  <Typography
                                    variant="body2"
                                    fontWeight={600}
                                    mb="0px"
                                  >
                                    {moment(+item.due_date).format("DD-MMM-YYYY")} |
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    textAlign="end"
                                    mb={0}
                                    color="#4A7AF2"
                                  >
                                    &nbsp; {moment(+item.due_date).format("dddd")}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box></Box>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </Box>
                  <IconButton sx={{ width: "5%", borderRadius: "0px" }}>
                    <ExpandCircleDownOutlined
                      sx={{ rotate: "-90deg" }}
                    ></ExpandCircleDownOutlined>
                  </IconButton>
                </Box>
               
              </Box>
            </Box> */}
          </Box>

          {/* ...................................... Right Side Content ................................... */}
          <Container
            //
            style={{ width: "440px", height: "fit-content", paddingRight: "0px" }}
          >
            <RightSideSection />
          </Container>
        </Box>
      </Container>
    </>
  );
}

export default Dashboard;
