import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Typography, Box, Button, IconButton, Divider, CircularProgress } from '@mui/material';
import Confetti from 'react-confetti';
import { CancelOutlined } from '@mui/icons-material';

const CelebrationPopup = ({ open, onClose, prizeAmount }: { open: boolean; onClose: () => void; prizeAmount: number }) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false); // Spinner visibility state

  useEffect(() => {
    if (open) {
      setShowConfetti(true);
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Confetti stops after 5 seconds
      return () => clearTimeout(timer); // Cleanup the timer on unmount or popup close
    }
  }, [open]);

  const handleDoneClick = () => {
    // Show the spinner when the button is clicked
    setIsSpinnerVisible(true);

    // Wait for a brief moment (simulate processing or animation)
    setTimeout(() => {
      // Refresh the page after the spinner is shown
      window.location.reload();
    }, 2000); // Adjust the delay as per your requirements
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { background: '#141414', width: '700px', height: 'fit-content', padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', overflow: 'hidden' } }}>
      {/* Confetti Effect */}
      {showConfetti && <Confetti style={{ height: '100%', width: '100%' }} recycle={false} numberOfPieces={2500} />}

      <DialogContent
        style={{
          textAlign: 'center',
          backgroundColor: '#000',
          color: '#fff',
          width: '100%',
          padding: '20px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <IconButton sx={{ mb: '24px', display: 'flex', justifyContent: 'end', width: '100%' }} onClick={handleDoneClick}>
          <CancelOutlined sx={{ height: '36px', width: '36px' }} />
        </IconButton>
        <Box display='flex' mb='30px' justifyContent='center' width='100%'>
          <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M189.364 116.566L189.306 116.595C182.451 121.852 179.546 130.799 181.986 139.077L182.015 139.136C185.907 152.294 176.264 165.568 162.553 165.917H162.495C153.839 166.15 146.229 171.668 143.353 179.831V179.86C138.763 192.815 123.136 197.898 111.836 190.084C104.82 185.293 95.5446 185.043 88.1625 190.084H88.1336C76.8344 197.869 61.2067 192.815 56.6461 179.831C53.7446 171.648 46.1454 166.149 37.504 165.917H37.4457C23.7356 165.568 14.0915 152.294 17.984 139.135L18.0129 139.077C20.4524 130.798 17.5477 121.852 10.693 116.594L10.6348 116.565C-0.258154 108.199 -0.258154 91.8169 10.6348 83.4513L10.693 83.4224C17.5477 78.1649 20.4524 69.2181 17.984 60.9395V60.8813C14.0622 47.7231 23.7352 34.4481 37.4457 34.0997H37.504C46.1309 33.8673 53.7704 28.3481 56.6461 20.186V20.1571C61.2063 7.20204 76.8344 2.11884 88.1336 9.9329H88.1625C95.2789 14.8419 104.69 14.8419 111.836 9.9329C123.25 2.05126 138.79 7.27978 143.353 20.1571V20.186C146.229 28.3192 153.839 33.8677 162.495 34.0997H162.553C176.264 34.4481 185.907 47.7231 182.015 60.8813L181.986 60.9395C179.546 69.2181 182.451 78.1649 189.306 83.4224L189.364 83.4513C200.257 91.8169 200.257 108.2 189.364 116.566Z" fill="#3EB655" />
            <path d="M100.108 154.216C129.991 154.216 154.216 129.991 154.216 100.108C154.216 70.2251 129.991 46 100.108 46C70.2251 46 46 70.2251 46 100.108C46 129.991 70.2251 154.216 100.108 154.216Z" fill="#8BD399" />
            <path d="M85.2136 122.582L73.2483 109.852C70.1148 106.518 70.2765 101.275 73.6097 98.1418C76.9429 95.0043 82.1878 95.1726 85.3187 98.5047L91.0304 104.579L115.309 76.8297C118.318 73.3859 123.553 73.0367 126.999 76.0504C130.443 79.064 130.791 84.2968 127.778 87.7406L97.4835 122.364C94.2562 126.048 88.5605 126.145 85.2136 122.582Z" fill="white" />
          </svg>

        </Box>

        <Typography fontSize='24px' mb='20px'>
          You Win
        </Typography>
        <Typography fontSize='64px' style={{ fontWeight: 800 }}>
          ${prizeAmount}
        </Typography>
        <Typography marginBottom='33px' fontSize='16px'>
          Congratulations! You got ${prizeAmount}. It will be added to your Wolfpack wallet.
        </Typography>
        <Divider sx={{ border: '1px solid #a3a3a3', marginBottom: '20px' }} />

        <DialogActions style={{ justifyContent: 'center', padding: '0px', paddingTop: '24px', backgroundColor: '#000' }}>
          <Button
            onClick={handleDoneClick} // Call the spinner and refresh handler
            className='btn btn-primary'
            sx={{ padding: '13px 60px', margin: '0px' }}
            disabled={isSpinnerVisible} // Disable the button when spinner is visible
          >
            {isSpinnerVisible ? (
              // Show the spinner when it's visible
              <CircularProgress size={24} sx={{ color: 'orange' }} />
            ) : (
              'Done'
            )}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CelebrationPopup;
