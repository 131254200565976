import React, { useEffect, useState } from 'react';
import axios from '../../../config/axios';
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material';
import { UrlConstant } from '../../../commom/UrlConstant';
import { getUserFromLocalStorage } from "../../../Utils/utils";
interface Transaction {
  companyName: string;
  symbol: string;
  time: string;
  stockPrice: number;
  qty: number;
  totalCost: number;
  type: 'Buy' | 'Sell';
  logoUrl: string;
}

const ExecutedOrder: React.FC = () => {
  const user = getUserFromLocalStorage();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const fetchExecutedOrders = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          UrlConstant.Order.orderList,
          {
            accountId: user?.tradingblock_application_id,
            pageIndex: '0',
            pageSize: '50',
            type: 'executed',
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const data = response.data.data.map((order: any) => ({
          companyName: order.companyName || 'Unknown',
          symbol: order.symbol || 'N/A',
          time: order.time || 'N/A',
          stockPrice: order.stockPrice || 0,
          qty: order.qty?.toFixed(2) || 0,
          totalCost: order.totalCost || 0,
          type: order.type || 'Buy',
          logoUrl: order.branding?.icon_url || order.branding?.logo_url,
        }));

        setTransactions(data);
      } catch (err) {
        console.error('Error fetching executed orders:', err);
        setError('Failed to fetch executed orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchExecutedOrders();
  }, []);

  return (
    <TableContainer
      component={Paper}
      style={{
        color: 'white',
        background: 'transparent',
        borderRadius: '20px',
        border: '2px solid #FFFFFF1A',
      }}
    >
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#FFA500',
          }}
        >
          <CircularProgress size={50} style={{ color: '#FFA500' }} />
        </Box>
      ) : error ? (
        <Typography
          variant="h6"
          style={{ color: 'red', textAlign: 'center', margin: '20px' }}
        >
          {error}
        </Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow
              sx={{
                '& th': { borderColor: '#FFFFFF1A', padding: '20px 30px' },
              }}
            >
              <TableCell style={{ fontSize: '12px', color: 'white' }}>
                Company
              </TableCell>
              <TableCell
                style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}
              >
                Code
              </TableCell>
              <TableCell
                style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}
              >
                Date & Time
              </TableCell>
              <TableCell
                style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}
              >
                Price
              </TableCell>
              <TableCell
                style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}
              >
                Qty
              </TableCell>
              <TableCell
                style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}
              >
                Amount
              </TableCell>
              <TableCell
                style={{ fontSize: '12px', color: 'white', textAlign: 'start' }}
              >
                Type
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: 'center', color: 'red', fontSize: '18px' }}>
                  No executed data available
                </TableCell>
              </TableRow>
            ) : (
              transactions.map((transaction, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '& td': { borderBottom: 'none !important', padding: '20px 30px' },
                  }}
                >
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Box
                        component="img"
                        src={transaction.logoUrl}
                        style={{
                          marginRight: '8px',
                          height: '36px',
                          width: '36px',
                          borderRadius: '50%',
                          backgroundColor: '#4A7AF2',
                        }}
                      />
                      <Typography style={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
                        {transaction.companyName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '16px',
                      color: 'white',
                      textAlign: 'start', fontWeight: 'bold'
                    }}
                  >
                    {transaction.symbol}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '16px',
                      color: 'white',
                      textAlign: 'start', fontWeight: 'bold'
                    }}
                  >
                    {new Date(transaction.time).toLocaleDateString()} |{' '}
                    <span style={{ color: '#4A7AF2' }}>{new Date(transaction.time).toLocaleTimeString()}</span>
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '16px',
                      color: 'white',
                      textAlign: 'start', fontWeight: 'bold'
                    }}
                  >
                    {`$${transaction.stockPrice.toFixed(2)}`}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '16px',
                      color: 'white',
                      textAlign: 'start', fontWeight: 'bold'
                    }}
                  >
                    {transaction.qty}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '16px',
                      color: 'white',
                      textAlign: 'start', fontWeight: 'bold'
                    }}
                  >
                    {`$${transaction.totalCost.toFixed(2)}`}
                  </TableCell>
                  <TableCell style={{ textAlign: 'start', fontWeight: 'bold' }}>
                    <Chip
                      label={transaction.type}
                      style={{
                        fontSize: '14px',
                        backgroundColor: transaction.type === 'Buy' ? '#4EDF6D33' : '#FF2A2A33',
                        color: transaction.type === 'Buy' ? '#4EDF6D' : '#FF2A2A',
                        padding: '5px 28px',
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>

        </Table>
      )}
    </TableContainer>
  );
};

export default ExecutedOrder;
