import { KeyboardArrowRight } from '@mui/icons-material'
import { Paper, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Avatar, Typography, IconButton, CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Axios from '../../../config/axios'
import { UrlConstant } from '../../../commom/UrlConstant'
import { getUserFromLocalStorage } from '../../../Utils/utils'
import { CommonDataTable } from '../SharedComponent/CommonDataTable'
import showPremiumAlert, { formatPrice, getCustomerInfoFromLocalStorage } from "../../../Utils/utils";
import { useNavigate } from 'react-router-dom'

interface StockData {
    company: string;
    symbol:string;
    icon: string;
    price: number;
    onedprice: number;
    onedpercentage: number;
    Buy_sell: string;
}
interface userProfileProps {
    type: 'Stock' | 'ETF';
}
function MyStocks({ type }: userProfileProps) {
    const user = getUserFromLocalStorage();
    const userId = user?._id;
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRows] = useState(40);
    const [search, setSearch] = useState("");
    const [data, setData] = useState<StockData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    console.log("Data", data)
    const handleStockClick = (stock: StockData) => {
        navigate("/stockdetail", {
            state: {
                symbol:stock.symbol,
                icon: stock.icon, // Ensure symbol is a word (company name)
                title: "Profile",
            },
        });
    };

    const columns = [
        {
            name: "Company",
            selector: (row) => [row.company],
            cell: (row) => (
                <div
                    // onClick={() => {
                    //     navigateToDetail(row);
                    // }}
                    className="stock-label"
                    style={{

                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        cursor: "pointer",
                    }}
                >
                    <Avatar
                        className="stock-icon"
                        alt={row.ticker}
                        src={row.icon || ''}
                        sx={{
                            width: 30,
                            height: 30,
                            backgroundColor: "white",
                            objectFit: "fill",
                        }}
                    />
                    <span
                        style={{
                            // whiteSpace: "nowrap",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                            whiteSpace: "normal",
                            fontWeight: "normal",
                            // width: "100%",

                        }}
                    >
                        {row.company}
                    </span>
                </div>
            ),
            sortable: true,
            center: false,
            width: "300px",
            //width: "auto",
        },
        {
            name: "Market Price",
            selector: (row) => [row.price],
            cell: (row) => (
                <div>
                    {row.price === null ? "-" : `$${row.price?.toFixed(2)}`}
                </div>
            ),
            width: "auto",
            // width: "a",
            sortable: true,
            center: true,
        },
        {
            name: "1D Price",
            selector: (row) => [row.onedprice],
            cell: (row) => {
                return (
                    <div
                        style={{
                            color: row.onedprice === 0 ? '' : row.onedprice > 0 ? '#4edf6d' : 'red',
                        }}
                    >
                        {row.onedprice > 0 ? "$" : "-$"}{Math.abs(row.onedprice)?.toFixed(2)}
                    </div>
                )
            },
            sortable: true,
            width: "auto",
            center: false,
        },

        {
            name: "1D Percentage",
            selector: (row) => [row.onedpercentage],
            sortable: true,
            cell: (row) => {

                return (
                    <div
                        style={{
                            color: row.onedpercentage === 0 ? '' : row.onedpercentage > 0 ? '#4edf6d' : 'red',
                        }}
                    >
                        {row.onedpercentage > 0 ? `+${formatPrice(row.onedpercentage)}` : formatPrice(row.onedpercentage)}%
                    </div>
                );
            },
            width: 'auto',
            center: false,
        },
        {
            name: "Buy/Sell",
            selector: (row) => [row.Buy_sell],
            sortable: true,
            cell: (row) => {

                return (
                    <Button sx={{
                        border: 'none',
                        backgroundColor: row.Buy_sell === 'Buy' ? '#4EDF6D33' : '#FF2A2A33',
                        color: row.Buy_sell === 'Buy' ? '#4EDF6D' : '#FF2A2A',
                        padding: '5px 24px',
                        borderRadius: '50px'
                    }}>
                        {row.Buy_sell}
                    </Button>
                );
            },
            width: 'auto',
            center: false,
        },
        {
            name: "Action",
            selector: (row) => [row.Buy_sell],
            sortable: true,
            cell: (row) => {

                return (
                    <IconButton
                        sx={{
                            padding: '8px',
                            borderRadius: '10px',
                            backgroundColor: '#4A7AF2',
                            '&:hover': {
                                backgroundColor: '#4A7AF2',
                            },
                        }}
                        onClick={() => handleStockClick(row)}
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                );
            },
            width: 'fit-content',
            center: false,
        },
    ];
    useEffect(() => {
        const fetchData = async () => {
            if (!userId) {
                console.error("User ID is undefined.");
                setLoading(false);
                return;
            }
            setData([]);
            setLoading(true);

            try {
                // Determine the URL based on the 'type' ('Stock' or 'ETF')
                const url = type === 'Stock'
                    ? UrlConstant.Profile.getTopPickStock.replace("{0}", userId)
                    : UrlConstant.Profile.getTopPickETF.replace("{0}", userId);

                // Fetch data using Axios
                const response = await Axios.post(url);

                // For the 'Stock' type, handle response based on the expected data structure
                const newArr = [...response.data.data.mostPopular.buy, ...response.data.data.mostPopular.sell];
                console.log("New Array", newArr);

                // Map the data to the required format
                const transformedData: StockData[] = newArr.map((stock: any) => {
                    return {
                        company: stock.displayName,
                        symbol:stock.symbol,
                        icon: stock.branding?.logo_url || "", // Default to empty string if logo is missing
                        price: parseFloat(stock.stockPrice),
                        onedprice: stock.changeAmount,
                        onedpercentage: stock.changePercentage,
                        Buy_sell: stock.recommedationType === 'BUY' ? 'Buy' : 'Sell',
                    };
                });

                // Set the transformed data to state
                setData(transformedData);
                setLoading(false);

            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userId, type]);
    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="calc( 100vh - 71px - 143px) ">
                    <CircularProgress style={{ color: '#FFA500' }} /> {/* Orange spinner */}
                </Box>
            ) : data.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="calc( 100vh - 71px - 143px) ">
                    <Typography variant="h6" style={{ color: 'white' }}>No Data Available</Typography>
                </Box>
            )
                : (
                    // <TableContainer component={Paper}
                    //     style={{
                    //         minHeight: 'calc(100vh - 131px - 240px)',
                    //         flex:'1',
                    //         height:'100%',
                    //         // overflow:'hidden',
                    //         overflowY:'auto',
                    //         color: 'white', background: 'transparent',
                    //         width:'100%',
                    //         borderRadius: '20px', border: '2px solid #FFFFFF1A',
                    //         position: 'relative',

                    //     }}
                    //     sx={{
                    //         "&::-webkit-scrollbar": {
                    //             width: "5px !important",
                    //             height: "40px !important",
                    //           },
                    //           "&::-webkit-scrollbar-track": {
                    //             background: "#FFFFFF1A",
                    //           },
                    //           "&::-webkit-scrollbar-thumb": {
                    //             backgroundColor: "#FFFFFF80",
                    //             borderRadius: "6px",
                    //           },
                    //           "&::-webkit-scrollbar-thumb:hover": {
                    //             backgroundColor: "#FFFFFF80",
                    //           },
                    //     }}
                    // >
                    //     <Table stickyHeader sx={{ height: '100%' }}>
                    //         <TableHead>
                    //             <TableRow sx={{ '& th': {width:'100%', borderColor: '#808080 !important', backgroundColor: '#808080 !important', zIndex: '999' } }}>
                    //                 <TableCell style={{ fontSize: '12px', color: 'white' }}>Company</TableCell>
                    //                 <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>Market Price</TableCell>
                    //                 <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>1D Price</TableCell>
                    //                 <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>1D Percentage</TableCell>
                    //                 <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>Buy/Sell</TableCell>
                    //                 <TableCell style={{ fontSize: '12px', color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>Action</TableCell>
                    //             </TableRow>
                    //         </TableHead>
                    //         <TableBody>
                    //             {data.map((row, index) => (
                    //                 <TableRow key={index} sx={{ '& td': { borderBottom: 'none !important' } }}>
                    //                     <TableCell>
                    //                         <Box display="flex" alignItems="center">
                    //                             <Avatar
                    //                                 src={row.symbol || ''} // Provide a fallback image
                    //                                 alt={row.company}
                    //                                 style={{ marginRight: '8px', height: '36px', width: '36px' }}
                    //                             />
                    //                             <Typography style={{ fontSize: '14px', color: 'white' }}>{row.company}</Typography>
                    //                         </Box>
                    //                     </TableCell>

                    //                     <TableCell sx={{ fontSize: "16px", color: 'white', textAlign: 'end', textWrap: 'nowrap' }}>
                    //                         {`$ ${row.price.toFixed(2)}`}
                    //                     </TableCell>
                    //                     <TableCell sx={{ fontSize: "16px", color: row.onedprice > 0 ? '#4EDF6D' : '#FF2A2A', textWrap: 'nowrap', textAlign: 'end' }}> {row.onedprice > 0 ? "$" : "-$"}{Math.abs(row.onedprice)?.toFixed(2)}</TableCell>
                    //                     <TableCell sx={{ fontSize: "16px", color: row.onedpercentage > 0 ? '#4EDF6D' : '#FF2A2A', textWrap: 'nowrap', textAlign: 'end' }}>{`(${row.onedpercentage}%)`}</TableCell>
                    //                     <TableCell sx={{ fontSize: "16px", textAlign: 'end' }}>
                    //                         <Button sx={{
                    //                             border: 'none',
                    //                             backgroundColor: row.Buy_sell === 'Buy' ? '#4EDF6D33' : '#FF2A2A33',
                    //                             color: row.Buy_sell === 'Buy' ? '#4EDF6D' : '#FF2A2A',
                    //                             padding: '5px 24px',
                    //                             borderRadius: '50px'
                    //                         }}>
                    //                             {row.Buy_sell}
                    //                         </Button>
                    //                     </TableCell>
                    //                     <TableCell style={{ fontSize: "16px", color: 'white', textAlign: 'end' }}>
                    //                         <IconButton
                    //                             sx={{
                    //                                 padding: '8px',
                    //                                 borderRadius: '10px',
                    //                                 backgroundColor: '#4A7AF2',
                    //                                 '&:hover': {
                    //                                     backgroundColor: '#4A7AF2', // Keep this the same if you don't want a change on hover
                    //                                 },
                    //                             }}>
                    //                             <KeyboardArrowRight />
                    //                         </IconButton>
                    //                     </TableCell>
                    //                 </TableRow>
                    //             ))}
                    //         </TableBody>
                    //     </Table>
                    // </TableContainer>
                    <Box sx={{ border: '1px solid #ffffff33', borderRadius: '20px' }}>
                        <CommonDataTable
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                            data={data}
                            totalRows={totalRows}
                            columns={columns}
                            setSearch={setSearch}
                            title={"My Stocks"}
                        />
                    </Box>

                )}
        </>
    )
}

export default MyStocks
