import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UrlConstant } from "../../commom/UrlConstant";
import Axios from "../../config/axios";
import LeftSide from "../common/Layout/LeftSide";
import { getUserFromLocalStorage } from "../../Utils/utils";
import { useDispatch } from "react-redux";
import { login, logout } from "../../Redux/authSlice";

function Terms() {
  const [is18YearOld, set18YearOld] = useState<Boolean>(false);
  const [isAccept, setIsAccept] = useState<Boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = location.state ? location.state : getUserFromLocalStorage();

  // Function to check if both checkboxes are checked
  const isFormValid = is18YearOld && isAccept;

  React.useEffect(() => {
    if (!user || user === null) {
      navigate("/login");
    }
  }, [user, navigate]);

  const acceptDisclosure = async () => {
    const payLoad = {
      user: { _id: user._id },
    };
    try {
      const response = await Axios.post(
        UrlConstant.Auth.AcceptDisclosure,
        payLoad,
        {
          headers: {
            Authorization: user.userToken,
          },
        }
      );

      const resData = response.data;
      // debugger;
      if (resData.result === true) {
        const updatedUser = { ...user, acceptDisclosure: true };
        dispatch(login(updatedUser));
        navigate("/uploadimage", { state: updatedUser });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

   const onCancelTerms = () => {
      dispatch(logout());
      navigate("/login");
    };

  return (
    <Container
      maxWidth={false}
      className="container_new"
      sx={{ maxWidth: "100%", margin: "0px", justifyContent: "start" }}
    >
      {/* <LeftSide /> */}
      <RightSection className="RightSection bg-color">
        <Typography
          className="Typography fw-700 mb-10 fs-24 heading"
          width="100%"
        >
          Terms & Conditions
        </Typography>
        <Box
          padding="30px"
          overflow="auto"
          height="70%"
          border="2px solid #FFFFFF33"
          borderRadius="30px"
          sx={{
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "100%",
            },
            "&::-webkit-scrollbar-track": {
              background: "#FFFFFF1A",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF80",
              borderRadius: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF80",
            },
          }}
        >
          <Typography
            variant="subtitle1"
            textAlign="justify"
            className="text-white"
          >
            Nothing provided on this website / mobile application by Wolfpack
            Financial Inc., including via any related materials (including
            newsletters, blog post, videos, social media and other
            communications) shall be considered solicitation for any purpose, in
            any form or content. We are not providing legal, accounting, or
            financial advisory services, and this is not a solicitation or
            recommendation to buy or sell any stocks, options, or other
            financial instruments or investments. Examples that address specific
            assets, stocks, options or other financial instrument transactions
            are for illustrative purposes only and may not represent specific
            trades or transactions that we have conducted. This site and any
            information or training therein is also not intended as a
            solicitation for any future relationship, business or otherwise
            between the members or participants and the moderators. No express
            or implied warranties are being made with respect to these services
            and products. Wolfpack Financial Inc. shall not be liable or
            responsible for any investment decision made by any entity on this
            website / mobile application. The user / you alone assume the sole
            responsibility of evaluating the merits and risks associated with
            the use of any information or other content on the website / mobile
            application before making any decisions based on such information.
            The user / you understand, acknowledge, and agree not to hold
            Wolfpack Financial Inc., its affiliates or any third party service
            provider liable for any possible claim for damages arising from any
            decision you make based on information or other content made
            available to you through the website / mobile application.\n\nAll
            investing and trading in the securities market involves risk. DO NOT
            BASE ANY INVESTMENT DECISION SOLELY UPON ANY MATERIAL FOUND ON THIS
            WEBSITE / MOBILE APPLICATION ALONE. Any decisions to place trades in
            the financial markets, including trading in stock or options or
            other financial instruments, is a personal decision that should only
            be made after thorough research, including a personal risk and
            financial assessment, and the engagement of independent professional
            assistance.
          </Typography>
        </Box>
        <Box my={1}>
          <FormGroup>
            <FormControlLabel
              className="custom-label"
              required
              control={
                <Checkbox
                  onChange={(e) => set18YearOld(e.target.checked)}
                  sx={{
                    "&.Mui-checked": {
                      color: "blue !important", // Color when checked
                    },
                  }}
                />
              }
              label="I am 18 years or older."
            />
            <FormControlLabel
              className="custom-label"
              required
              control={
                <Checkbox onChange={(e) => setIsAccept(e.target.checked)} />
              }
              label="I accept the terms & condition mentioned above."
            />
          </FormGroup>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!isFormValid}
          className="btn-primary fw-700 fs-18  btn mb-10"
          endIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 22C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15Z"
                stroke="white"
                stroke-opacity={isFormValid ? 1 : 0.2}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.75 12L10.58 14.83L16.25 9.17004"
                stroke="white"
                stroke-opacity={isFormValid ? 1 : 0.2}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
          type="submit"
          sx={{
            "&.Mui-disabled": {
              backgroundColor: "#FFFFFF33 !important", // Custom disabled background color
              color: "#FFFFFF33 !important", // Custom disabled text color
              opacity: 0.7, // Adjust opacity if needed
            },
            paddingY: "12px",
          }}
          onClick={acceptDisclosure}
        >
          Agree
        </Button>
        <Typography onClick={()=>onCancelTerms()} sx={{cursor:"pointer"}} className="link">
          Cancel
        </Typography>
      </RightSection>
    </Container>
  );
}

const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  // padding: "4rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "70px 120px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("sm")]: {
    padding: "40px 40px",
  },
}));
export default Terms;
