// import { Avatar, Container,ToggleButton,Typography,Box,FormControl } from "@mui/material";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CardContent,
  IconButton,
  CircularProgress,
  Divider,
  Button
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Card, Row } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import LineChart from "../charts/Linechart";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../../Redux/loadingSlice";
import Axios from "../../../config/axios";
import { UrlConstant } from "../../../commom/UrlConstant";
import Swal from "sweetalert2";
import { formatPrice, getCurrentUserId, getUserFromLocalStorage } from "../../../Utils/utils";
import BuySell from "../Cards/BuySell";
import axios from "axios";
import { Grid } from "@material-ui/core";
import moment from "moment";
import TransactionHistoryTable from "../Tabels/PortfolioTransactionTabel";

const BAR_CHART_KEY = "0J7lfEpAefOuHSE6uSHdiW_5N0sThxfa";
const YAHOO_FINANCE_KEY = "W6WQ7T5PIt63txWaMW9yVsDCVVYbUD81i0eERo32";
const BASE_URL = "https://yfapi.net/ws/insights/v1/finance/insights";


function PortfolioStockDetail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [stockDetail, setStockDetail] = React.useState<any>({});
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<any>([]);
  const [tickerData, setTickerData] = useState<any>([]);
  const [tickerDataAll, setTickerAllData] = useState<any>([]);
  const [transactionHistory, setTransactionHistory] = useState<any>([]);
  const [selection, setSelection] = useState<string>("1 Month");
  const [expanded, setExpanded] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const user = getUserFromLocalStorage();


  const handleselect = (
    event: React.MouseEvent<HTMLElement>,
    newselection: string
  ) => {
    setChartData([]);
    setSelection(newselection);
  };

  const stock = location.state?.symbol;
  const stockInfo = location.state?.stockInfo;
  const icon = location.state?.icon;
  const title = location.state?.title


  function calculatePriceDetails(tickerData) {
    const lastPrice = parseFloat(tickerData?.Last || "0.00");
    const previousClose = parseFloat(tickerData?.PreviousClose || "0.00");
    const diffAmount = lastPrice - previousClose;
    const percentage = (diffAmount * 100.0) / previousClose;

    return {
      lastPrice,
      previousClose,
      diffAmount,
      percentage
    };
  }

  const getStockDetail = async () => {
    dispatch(startLoading());
    setLoading(true)
    try {
      const response = await Axios.get(
        UrlConstant.Stock.GetStockDetail.replace("{0}", stock)
      );
      const resDataTicker = await Axios.get(
        UrlConstant.Stock.GetTiker.replace("{0}", stock))

      if (resDataTicker.data) {
        setTickerData(calculatePriceDetails(resDataTicker.data?.data[0]))
        setTickerAllData(resDataTicker.data?.data[0])
      }
      const resData = response.data;
      if (resData.result === true) {
        setStockDetail(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
      setLoading(false)
    }
  };
  const getTransactionHistory = async () => {
    dispatch(startLoading());
    setLoading(true)
    const data = {
      "accountId": user?.tradingblock_application_id,
      "pageSize": "50",
      "pageIndex": "0",
      "symbol": stock
    }
    try {
      const response = await Axios.post(
        UrlConstant.Portfolio.TransactionHistory, data
      );

      const resData = response.data;
      if (resData.status === true) {
        setTransactionHistory(resData.data);
      }
    } catch (error: any) {
      console.error("ERRR : ", error);
    } finally {
      dispatch(endLoading());
      setLoading(false)
    }
  };


  const getUrlRange = (): string => {
    const now = new Date(new Date().setDate(new Date().getDate()));
    let endPoint = "";

    const formatDate = (date: Date) =>
      date.toISOString().split("T")[0]; // Format as yyyy-MM-dd

    const adjustDate = (date: Date, adjust: { days?: number; months?: number; years?: number }) => {
      if (adjust.days) date.setDate(date.getDate() + adjust.days);
      if (adjust.months) date.setMonth(date.getMonth() + adjust.months);
      if (adjust.years) date.setFullYear(date.getFullYear() + adjust.years);
      return date;
    };
    console.log(selection, adjustDate(new Date(now), { days: -1 }), "selectionselection")
    switch (selection) {
      case "1 Day":
        endPoint = `/range/15/minute/${formatDate(adjustDate(new Date(now), { days: -1 }))}/${formatDate(now)}`;
        break;
      case "5 Day":
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { days: -8 }))}/${formatDate(now)}`;
        break;
      case "1 Month":
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { months: -1 }))}/${formatDate(now)}`;
        break;
      case "6 Month":
        endPoint = `/range/1/week/${formatDate(adjustDate(new Date(now), { months: -6 }))}/${formatDate(now)}`;
        break;
      case "1 Year":
        endPoint = `/range/1/month/${formatDate(adjustDate(new Date(now), { years: -1 }))}/${formatDate(now)}`;
        break;
      case "5 Years":
        endPoint = `/range/1/month/${formatDate(adjustDate(new Date(now), { years: -5 }))}/${formatDate(now)}`;
        break;
      case "All":
        endPoint = `/range/1/month/1980-01-01/${formatDate(now)}`;
        break;
      default:
        endPoint = `/range/1/day/${formatDate(adjustDate(new Date(now), { days: -1 }))}/${formatDate(now)}`;
    }
    return endPoint;
  };

  const getChartData = async () => {
    dispatch(startLoading());
    console.log(getUrlRange(), "getUrlRange()")
    const fetchChartData = async (retryCount = 2) => {
      let currentDate = new Date();
      let retries = 0;

      while (retries <= retryCount) {
        // Adjust date range for the "1 Day" case
        const adjustedDate = new Date(currentDate);
        adjustedDate.setDate(currentDate.getDate() - retries);

        const startDate = adjustedDate.toISOString().split("T")[0];
        const endDate = new Date().toISOString().split("T")[0];

        const url = `https://api.polygon.io/v2/aggs/ticker/${stock}/range/15/minute/${startDate}/${endDate}?apiKey=${BAR_CHART_KEY}&sort=asc&limit=5000`;

        try {
          const response = await axios.get(url);
          console.log(response.data, "response.data");

          if (response.data.queryCount > 0) {
            const apiResponse = response.data.results;
            const chartData = apiResponse.map(item => ({
              x: new Date(item.t), // Convert Unix timestamp to Date
              y: item.c // Extract closing price "c"
            }));

            console.log(chartData, "chartData");
            setChartData(chartData);
            return; // Exit the loop and function when data is found
          } else {
            console.log(`No data for date range: ${startDate} - ${endDate}, retrying...`);
          }
        } catch (error) {
          console.error(`Error fetching chart data for range ${startDate} - ${endDate}:`, error);
        }

        retries++;
      }

      console.log("No data found after all retries");
      setChartData([]); // Set empty data if all retries fail
    };

    if (selection === "1 Day") {
      await fetchChartData();
    } else {

      try {
        const url = `https://api.polygon.io/v2/aggs/ticker/${stock}${getUrlRange()}?apiKey=${BAR_CHART_KEY}&sort=asc&limit=5000`;
        const response = await axios.get(url);
        console.log(response.data, "response.dataresponse.data")
        const apiResponse = response.data.results
        const chartData = apiResponse.length && apiResponse.map(item => ({
          x: new Date(item.t),  // Convert Unix timestamp to Date
          y: item.c            // Extract closing price "c"
        }));

        console.log(chartData, "chartDatachartDatachartData")
        setChartData(chartData || []);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        dispatch(endLoading());
      }
    }
  };

  const onBuySell = () => {
    navigation("/stockdetail", {
      state: {
        symbol: stock,
        icon: icon,
        title: "Portfolio"
      },
    });
  }



  useEffect(() => {
    getStockDetail();
    getTransactionHistory();
  }, [stock]);

  useEffect(() => {
    getChartData();
  }, [selection]);


  return (
    <Box className="bg-color" minHeight='calc(100vh - 71px)' display="flex">
      <Container
        maxWidth={false}
        className="bg-color p-3 px-5"
        style={{
          // display: "flex",
          height: "fit-content",
          width: "70%",
        }}
      >
        <Typography
          className="text-secondary"
          sx={{ marginTop: "30px", marginBottom: "30px" }}
        >

          <span
            onClick={() => navigation(-1)}
            style={{ color: "#4A7AF2", cursor: "pointer" }}
          >
            {title}
          </span>
          <span> / {stock}</span>
        </Typography>

        <Container
          style={{ width: "100%", left: "0", margin: "0" }}
          className="p-0"
        >
          <Box>
            <Box
              display="flex"
              alignItems="start"
              justifyContent="space-between"
            >
              <Box className="text-white">
                <Box display="flex" alignItems="center" gap="20px" mb={2}>
                  {icon ? (
                    <Avatar
                      alt={stock}
                      src={icon}
                      sx={{
                        width: 64,
                        height: 64,
                        backgroundColor: "white",
                        objectFit: "fill",
                      }}
                      className="icon"
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 64,
                        height: 64,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#212529" }}
                      >
                        {stock}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    <Typography mb={1} variant="h5">
                      {stockDetail?.displayName}
                    </Typography>
                    <Typography variant="body1" className="text-secondary">
                      {stockDetail?.stockExchangeCode}:{stockDetail?.symbol}
                    </Typography>
                  </Box>
                </Box>

              </Box>

              <Box className="text-white" display="flex" gap="10px">
                <Typography variant="h5" textAlign="end">
                  ${formatPrice(tickerData?.lastPrice)}
                </Typography>
                <Typography variant="h5" style={{ color: tickerData.diffAmount > 0 ? "#4edf6d" : "#ff2a2a" }}>
                  {/* -$1.35(0.01%) */}
                  ${tickerData.diffAmount?.toFixed(2)}({tickerData.percentage?.toFixed(2)}%)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Row className="dark-tbl no-border p-0 mt-5">
            <Card
              className="custom-card overflow-hidden bg-color w-100"
              style={{ height: "fit-content" }}
            >
              <Card.Body className="ps-12 p-2 h-100">
                <LineChart data={chartData} timeline={selection} />
                <hr style={{ borderColor: "#FFFFFF", borderWidth: "1px" }}></hr>
                <Box mb={1}>
                  <ToggleButtonGroup
                    // className="border-white"
                    // color="primary"
                    // fullWidth
                    value={selection}
                    exclusive
                    onChange={handleselect}
                    aria-label="Platform"
                  >
                    <ToggleButton value="1 Day">1 Day</ToggleButton>
                    <ToggleButton value="5 Day">5 Day</ToggleButton>
                    <ToggleButton value="1 Month">1 Month</ToggleButton>
                    <ToggleButton value="6 Month">6 Month</ToggleButton>
                    <ToggleButton value="1 Year">1 Year</ToggleButton>
                    <ToggleButton value="5 Years">5 Years</ToggleButton>
                    <ToggleButton value="All">Max</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Card.Body>
            </Card>
          </Row>
        </Container>

        {/* <Container style={{ width: "70%", left: "0", margin: "0" }}>
            <TotalFunds />
          </Container> */}
        <TransactionHistoryTable transactionHistory={transactionHistory} />

      </Container>
      <Container style={{ width: "30%", paddingTop: "20px" }}>
        <Box width="100%">
          <Typography
            component="h1"
            fontSize={24}
            mb={2}
            className="text-white"
          >
            Stock Details
          </Typography>
          <Card className="card" style={{ width: "100%", minHeight: '300px', paddingBottom: "0px" }}>
            <CardContent sx={{ padding: "20px" }}>
              <Box display={"flex"} justifyContent={"space-between"} paddingBottom={"10px"}>
                <Typography className="text-secondary">Qty.(Whole)</Typography>
                <Typography className="text-white">{formatPrice(stockInfo?.quantity)}</Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} paddingBottom={"10px"}>
                <Typography className="text-secondary">Current Price (per share)</Typography>
                <Typography className="text-white">${formatPrice(stockInfo?.quote?.lastPrice)}</Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} paddingBottom={"10px"}>
                <Typography className="text-secondary">Change (24 Hrs.)</Typography>
                <Typography className="text-white">
                  <span style={{ color: stockInfo.gainPercent == 0 ? " " : stockInfo.gainPercent > 0 ? "#4EDF6D" : "red", fontWeight: 'bold' }}>{formatPrice(stockInfo.gainPercent)}%</span>
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} paddingBottom={"10px"}>
                <Typography className="text-secondary">Invested</Typography>
                <Typography className="text-white">${formatPrice(stockInfo?.costValue)}</Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} paddingBottom={"10px"}>
                <Typography className="text-secondary">Market Value</Typography>
                <Typography className="text-white">${formatPrice(stockInfo?.value)}</Typography>
              </Box>
              {/* <Box display={"flex"} justifyContent={"space-between"} paddingBottom={"10px"}>
                <Typography className="text-secondary">Cash</Typography>
                <Typography className="text-white">{stockInfo?.cash?.toFixed(2)}</Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} paddingBottom={"10px"}>
                <Typography className="text-secondary">Booster Loan</Typography>
                <Typography className="text-white">{stockInfo?.loanDetail?.loanAmount}</Typography>
              </Box> */}
              <Divider sx={{ borderColor: '#FFFFFF70', marginY: 1 }} />
              <Box gap={2} display={"flex"} justifyContent={"space-between"} marginTop={"20px"}>
                <Button
                  onClick={() => { onBuySell() }}
                  //  sx={{marginTop:'20px'}}

                  variant="contained"
                  // color="primary"
                  // fullWidth
                  className="text-dark  fw-700 fs-18  btn mb-10"
                  sx={{ width: '100%', bgcolor: "#4edf6d !important", padding: '10px !important' }}
                >
                  Buy
                </Button>
                <Button
                  onClick={() => { onBuySell() }}
                  //  sx={{marginTop:'20px'}}

                  variant="contained"
                  // color="primary"
                  // fullWidth
                  className="text-dark  fw-700 fs-18  btn mb-10"
                  sx={{ width: '100%', bgcolor: "#FF2A2A !important", padding: '10px !important' }}
                >
                  Sell
                </Button>
              </Box>
            </CardContent>

          </Card>


        </Box>
      </Container>

      {loading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.5)" // Semi-transparent black background
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            backdropFilter: "blur(5px)", // Blur effect
            zIndex: 9999, // Ensures the loader stays above all elements
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}

    </Box>
  );
}
export default PortfolioStockDetail;
