import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog, selectDialogOpen } from "../../../Redux/dialogSlice";
import UpgradeNowGlobal from "./UpgradeNowGlobal";

const PremiumPopupHandler = () => {
  const isOpen = useSelector(selectDialogOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return isOpen ? <UpgradeNowGlobal onClose={handleClose} /> : null;
};

export default PremiumPopupHandler;
