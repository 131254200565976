import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Tooltip } from "@mui/material";

const FormDynamicElement = ({
  elementDetail,
  onChange,
  currentStepData,
  selectedValues,
  setSelectedValues,
  validationErrors,
}) => {
  const [childElements, setChildElements] = useState([]);
  const [showChildElements, setShowChildElements] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerCheckRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      const links = containerRef.current.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    }
    if (containerCheckRef.current) {
      const links = containerCheckRef.current.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    }
  }, [elementDetail]);

  const renderChildElements = () => {
    return currentStepData.filter((element, index) => {
      if (element.parent_question_id) {
        const parentSelectedValue = selectedValues[element.parent_question_id];
        const parentOptionSelected = selectedValues[element.parent_option_id];

        if (
          (parentSelectedValue && parentSelectedValue !== "") ||
          parentOptionSelected
        ) {
          return (
            <FormDynamicElement
              key={index}
              elementDetail={element}
              onChange={onChange}
              currentStepData={currentStepData}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              validationErrors={validationErrors}
            />
          );
        }
      }
      return false;
    });
  };

  useEffect(() => {
    if (elementDetail.options) {
      const selectedValue = selectedValues[elementDetail.id];
      if (selectedValue !== undefined) {
        renderChildElements();
      }
    }
  }, [selectedValues, elementDetail.id, currentStepData]);

  useEffect(() => {
    if (
      currentStepData[0] &&
      currentStepData[0].section_type === "beneficiaries_users"
    ) {
      if (selectedValues[0] === "false" || selectedValues[0] === false) {
        setChildElements([]);
        setShowChildElements(false);
      }
    } else if (elementDetail.parent_question_id) {
      const parentElement = currentStepData.find(
        (el) => el.id === elementDetail.parent_question_id
      );
      if (parentElement) {
        const parentSelectedValue = selectedValues[parentElement.id];
        const parentOptionSelected = selectedValues[parentElement.option_id];

        if (
          parentSelectedValue === "false" ||
          parentSelectedValue === false ||
          parentOptionSelected
        ) {
          setShowChildElements(true);
        } else {
          setChildElements([]);
          setShowChildElements(false);
        }
      }
    }
  }, [selectedValues, elementDetail.parent_question_id, currentStepData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const formattedValue = applyDynamicMask(value, elementDetail);
    setSelectedValues((prev) => ({ ...prev, [Number(name)]: formattedValue }));
    onChange({ ...elementDetail, value: formattedValue });
  };
  const applyDynamicMask = (value, elementDetail) => {
    // Check the field type and apply the respective mask
    if (elementDetail.system_identifire.includes("ssn")) {
      return applySSNMask(value);
    }
    return value;
  };
  const applySSNMask = (value: string) => {
    const numbers = value.replace(/\D/g, "");

    const formatted = numbers
      .slice(0, 9)
      .replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");

    return formatted;
  };

  const renderElement = (elDetail) => {
    const errorMessage = validationErrors[elDetail.id];

    switch (elDetail.type) {
      case "text":
        return (
          <FormControl
            style={{
              width: "28vw",
            }}
            margin="normal"
          >
            <label className="text-secondary" style={{ marginBottom: "10px" }}>
              <span>
                {elDetail.title}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{
                        color: "#FFFFFF",
                        marginLeft: "5px",
                        fontSize: "1rem",
                      }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>
            {elDetail.dynamichint !== null && (
              <div style={{ color: "#a3a3a3", fontSize: "13px" }}>
                {elDetail.dynamichint}
              </div>
            )}

            <TextField
              name={elDetail.id.toString()}
              value={elDetail.value || ""}
              variant="outlined"
              onChange={handleChange}
            />
            {errorMessage && (
              <div style={{ color: "red" }}>
                {elDetail.error ? elDetail.error : errorMessage}
              </div>
            )}
          </FormControl>
        );

      case "radio":
        return (
          <Box minWidth="70vw">
            <label className="text-secondary">
              <span>
                {elDetail.title}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#FFFFFF", fontSize: "1rem" }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>
            <RadioGroup
              name={elDetail.id.toString()}
              value={elDetail.value || ""}
              onChange={handleChange}
            >
              {elDetail.options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.answer}
                  control={<Radio />}
                  label={option.title}
                  className="text-secondary"
                />
              ))}
            </RadioGroup>

            {errorMessage && (
              <div style={{ color: "red" }}>
                {elDetail.error ? elDetail.error : errorMessage}
              </div>
            )}
          </Box>
        );

      case "select":
        return (
          <div style={{ width: "28vw", marginTop: "5px" }}>
            <label className="text-secondary" style={{ marginBottom: "10px" }}>
              <span>
                {elDetail.title}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#FFFFFF", fontSize: "1rem" }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>
            <FormControl fullWidth>
              <Select
                name={elDetail.id.toString()}
                value={elDetail.value || ""}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  p: "13px",
                  mb: 0,
                  " .MuiFormControl-root": {
                    margin: "0px",
                    marginTop: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: errorMessage
                        ? "#f44336 !important"
                        : "#a3a3a3 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: errorMessage
                        ? "#f44336 !important"
                        : "#f2674a !important",
                    },
                  },
                }}
              >
                {elDetail.options.map((option, index) => (
                  <MenuItem key={option.id + index} value={option.answer}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorMessage && (
              <div style={{ color: "red" }}>
                {elDetail.error ? elDetail.error : errorMessage}
              </div>
            )}
          </div>
        );

      case "checkbox":
        return (
          <div
            style={{
              display: "block",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <label style={{ color: "#A3A3A3" }}>
              <span className="custom-html-section">
                {elDetail.options && (
                  <span ref={containerRef}>
                    {elDetail.options.map((op: any) => (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: op.title,
                        }}
                      />
                    ))}
                  </span>
                )}
                <Box display="flex" width="100%" alignItems="center" gap="10px">
                  <input
                    type="checkbox"
                    style={{ height: "20px", width: "20px" }}
                    checked={elDetail.value || false}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          // name: elDetail.id,
                          name: elDetail.id.toString(),
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                  <Box>
                    {elDetail.button_text}
                    {elDetail.isOptional === undefined && (
                      <sup style={{ marginLeft: "5px" }}>
                        <i
                          className="fa-solid fa-star-of-life"
                          style={{ color: "#fa0000", fontSize: "0.5rem" }}
                        ></i>
                      </sup>
                    )}
                    {elDetail.hint && (
                      <Tooltip
                        title={elDetail.hint}
                        arrow
                        sx={{ backgroundColor: "purple" }}
                      >
                        <i
                          className="fa-solid fa-info-circle"
                          style={{ color: "#FFFFFF", fontSize: "1rem" }}
                        ></i>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </span>
            </label>
            {/* {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>} */}
            {errorMessage && (
              <div style={{ color: "red" }}>
                {elDetail.error ? elDetail.error : errorMessage}
              </div>
            )}{" "}
          </div>
        );

      case "date":
        const currentDate = new Date();
        const minDate = new Date(currentDate);
        minDate.setFullYear(currentDate.getFullYear() - 18);

        return (
          <div style={{ width: "28vw", marginTop: "5px" }}>
            <label className="text-secondary" style={{ marginBottom: "10px" }}>
              <span>
                {elDetail.title}
                {elDetail.isOptional === undefined && (
                  <sup>
                    <i
                      className="fa-solid fa-star-of-life"
                      style={{ color: "#fa0000", fontSize: "0.5rem" }}
                    ></i>
                  </sup>
                )}
                {elDetail.hint && (
                  <Tooltip
                    title={elDetail.hint}
                    arrow
                    sx={{ backgroundColor: "purple" }}
                  >
                    <i
                      className="fa-solid fa-info-circle"
                      style={{ color: "#FFFFFF", fontSize: "1rem" }}
                    ></i>
                  </Tooltip>
                )}
              </span>
            </label>
            <DatePicker
              showIcon
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={elDetail.value}
              maxDate={
                elementDetail.system_identifire === "dateOfBirth"
                  ? minDate
                  : undefined
              }
              onChange={(date) => {
                console.log("DATE : ", date);
                handleChange({
                  target: { name: elDetail.id.toString(), value: date },
                });
              }}
            />
            {errorMessage && (
              <div style={{ color: "red" }}>
                {elDetail.error ? elDetail.error : errorMessage}
              </div>
            )}
          </div>
        );
      case "link":
        return (
          <div className="custom-html-section" ref={containerRef}>
            <div dangerouslySetInnerHTML={{ __html: elementDetail.title }} />
          </div>
        );

      default:
        return (
          <div style={{ color: "red" }}>
            Unknown Element Type: {elDetail.type}
          </div>
        );
    }
  };

  return (
    <div>
      {renderElement(elementDetail)}

      {/* Conditionally render child elements if applicable */}
      {showChildElements &&
        childElements.length > 0 &&
        childElements.map((childElement, index) => (
          <FormDynamicElement
            key={index + 100}
            elementDetail={childElement}
            onChange={onChange}
            currentStepData={currentStepData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            validationErrors={validationErrors}
          />
        ))}
    </div>
  );
};

export default FormDynamicElement;
