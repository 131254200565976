import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    IconButton,
    Divider,
    TextField,
    InputAdornment,
} from "@mui/material";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import { UrlConstant } from "../../../commom/UrlConstant";
import Axios from "../../../config/axios";
import { useFormik } from "formik";
import { CircularProgress } from "@mui/material";
import * as Yup from "yup";

interface FormDataType {
    name: string;
    total_amount: string;
    frequency_in_weeks: string;
    duration_in_weeks: string;
    monthly_topup: string;
    total_topup: string;
    start_date: Date | null; // Updated type
}
const validationSchema = Yup.object({
    name: Yup.string().required("Topup Name is required"),
    total_amount: Yup.string().required("Topup Amount is required"),
    start_date: Yup.date().nullable().required("Start Date is required"),
});

function CreateAutoTopupPopup({ open, onClose }: { open: boolean; onClose: () => void }) {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<FormDataType>({
        name: "",
        total_amount: "",
        frequency_in_weeks: "1",
        duration_in_weeks: "1",
        monthly_topup: "",
        total_topup: "",
        start_date: null, // Start with null
    });

    const resetFormData = () => {
        setFormData({
            name: "",
            total_amount: "",
            frequency_in_weeks: "1",
            duration_in_weeks: "1",
            monthly_topup: "",
            total_topup: "",
            start_date: null as Date | null,
        });
    };
    const [validDurations, setValidDurations] = useState<string[]>([]);
    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);
    };
    const handleSubmit = async () => {
        setLoading(true);
        console.log(formik.values);

        try {
            const response = await Axios.post(
                UrlConstant.AutoTopup.CreateAutoTopupPopup,
                formik.values // Use Formik values
            );

            if (response.status >= 200 && response.status < 300) {
                console.log("Data submitted successfully");

                // Reset form and close dialog
                formik.resetForm();
                onClose();
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                console.error("Error submitting data");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            setLoading(false);
        }
    };
    const formik = useFormik<FormDataType>({
        initialValues: {
            name: "",
            total_amount: "",
            frequency_in_weeks: "1",
            duration_in_weeks: "1",
            monthly_topup: "",
            total_topup: "",
            start_date: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });
    useEffect(() => {
        const frequency = parseInt(formik.values.frequency_in_weeks, 10);
        const durations: string[] = [];
        for (let i = frequency; i <= 52; i += frequency) {
            durations.push(i.toString());
        }
        setValidDurations(durations);

        if (!durations.includes(formik.values.duration_in_weeks)) {
            formik.setFieldValue("duration_in_weeks", durations[0]);
        }
    }, [formik.values.frequency_in_weeks]);

    // Automatically calculate monthly topup and total topup based on the selected values
    useEffect(() => {
        const totalAmount = parseFloat(formik.values.total_amount) || 0;
        const frequency = parseInt(formik.values.frequency_in_weeks, 10);
        const duration = parseInt(formik.values.duration_in_weeks, 10);

        if (totalAmount && frequency && duration) {
            const totalTopup = totalAmount;
            const monthlyTopup = totalTopup / duration;

            formik.setFieldValue("total_topup", totalTopup.toFixed(2));
            formik.setFieldValue("monthly_topup", monthlyTopup.toFixed(2));
        } else {
            formik.setFieldValue("total_topup", "");
            formik.setFieldValue("monthly_topup", "");
        }
    }, [formik.values.total_amount, formik.values.frequency_in_weeks, formik.values.duration_in_weeks]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const getTopupLabel = (frequency: string) => {
        switch (frequency) {
            case "1":
                return "Weekly Topup";
            case "2":
                return "Fortnightly Topup";
            case "3":
                return "Triweekly Topup";
            case "4":
                return "Monthly Topup";
            default:
                return "Topup Amount";
        }
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={false}
            PaperProps={{
                sx: {
                    width: "620px",
                    height: "710px",
                    borderRadius: "10px",
                    backgroundColor: "#141414 !important",
                    color: "white",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)", // Centering
                    boxShadow: "0px 10px 30px rgba(170, 170, 170, 0.7)", // Add shadow effect
                },
            }}
        >
            <DialogTitle sx={{
                width: "100%",
                padding: "20px",
                backgroundColor: "#141414",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <Typography variant="h6" sx={{ color: "white" }}>
                    Create Auto Topup
                </Typography>
                <IconButton
                    onClick={onClose}
                    sx={{
                        color: "white",
                        backgroundColor: "#141414",
                        borderRadius: "50%",
                        width: "36px",
                        height: "36px",
                    }}
                >
                    <CloseIcon sx={{ fontSize: "20px" }} />
                </IconButton>
            </DialogTitle>

            <Divider sx={{ backgroundColor: "gray", marginTop: "0px" }} />
            <Box position="relative">
                {loading && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="rgba(0, 0, 0, 0.5)"
                        zIndex={1}
                    >
                        <CircularProgress size={60} sx={{ color: "orange" }} />
                    </Box>
                )}
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent sx={{ padding: "20px", overflow: "auto", maxHeight: "calc(100vh - 200px)" }}>
                    <Box display="flex" flexDirection="column" gap={3}>
                        {/* Form Fields */}
                        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                            <InputLabel shrink={false} sx={{ color: "gray !important", marginTop: '10px' }}>
                                Topup Name
                                <sup style={{ color: "red", marginLeft: "5px" }}>*</sup>
                            </InputLabel>
                            <TextField
                                sx={{ marginTop: '10px', color: "white !important" }}
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                InputLabelProps={{
                                    style: { color: "gray !important" }, // Label color remains unchanged
                                }}
                                InputProps={{
                                    style: {
                                        color: "white !important",
                                        backgroundColor: "#141414",
                                        borderRadius: "8px",
                                    },
                                }}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                            <InputLabel shrink={false} sx={{ color: "gray !important" }}>
                                Topup Amount
                                <sup style={{ color: "red", marginLeft: "5px" }}>*</sup>
                            </InputLabel>
                            <TextField
                                name="total_amount"
                                value={formik.values.total_amount}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.total_amount && Boolean(formik.errors.total_amount)}
                                helperText={formik.touched.total_amount && formik.errors.total_amount}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography sx={{ color: "white" }}>AUD</Typography>
                                        </InputAdornment>
                                    ),
                                    style: { height: "56px" },
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                            <InputLabel shrink={false} sx={{ color: "gray !important" }}>
                                Start Date
                                <sup style={{ color: "red", marginLeft: "5px" }}>*</sup>
                            </InputLabel>
                            <DatePicker
                                selected={formik.values.start_date} // Use formik values directly
                                onChange={(date: Date | null) => formik.setFieldValue("start_date", date)} // Set value in formik
                                minDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select a start date"
                                showIcon
                                className="datePickerInput"
                                wrapperClassName="datePickerWrapper"
                            />

                            {formik.touched.start_date && formik.errors.start_date && (
                                <Typography color="error" variant="caption">
                                    {formik.errors.start_date}
                                </Typography>
                            )}
                        </FormControl>
                        <Box display="flex" gap={2}>
                            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                                <InputLabel shrink={false} sx={{ color: "gray !important" }}>
                                    Frequency
                                    <sup style={{ color: "red", marginLeft: "5px" }}>*</sup>
                                </InputLabel>
                                <Select
                                    name="frequency_in_weeks"
                                    value={formik.values.frequency_in_weeks}
                                    onChange={formik.handleChange}
                                    sx={{
                                        color: "white",
                                        backgroundColor: "#141414",
                                        borderRadius: "8px",
                                        height: "56px",
                                        "& .MuiSelect-icon": { color: "white" },
                                    }}
                                    required
                                >
                                    <MenuItem value="1">1 Week</MenuItem>
                                    <MenuItem value="2">2 Weeks</MenuItem>
                                    <MenuItem value="3">3 Weeks</MenuItem>
                                    <MenuItem value="4">4 Weeks</MenuItem>
                                </Select>

                            </FormControl>

                            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                                <InputLabel shrink={false} sx={{ color: "gray !important" }}>
                                    Duration
                                    <sup style={{ color: "red", marginLeft: "5px" }}>*</sup>
                                </InputLabel>
                                <Select
                                    name="duration_in_weeks"
                                    value={formik.values.duration_in_weeks}
                                    onChange={formik.handleChange}
                                    sx={{
                                        color: "white",
                                        backgroundColor: "#141414",
                                        borderRadius: "8px",
                                        height: "56px",
                                        "& .MuiSelect-icon": { color: "white" },
                                    }}
                                    required
                                >
                                    {validDurations.map((duration) => (
                                        <MenuItem key={duration} value={duration}>
                                            {duration} Weeks
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box display="flex" gap={2}>
                            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                                <InputLabel shrink={false} sx={{ color: "gray !important" }}>
                                    {getTopupLabel(formik.values.frequency_in_weeks)}
                                </InputLabel>
                                <TextField
                                    name="monthly_topup"
                                    value={`${formik.values.monthly_topup} AUD`} // Append AUD to the value
                                    InputProps={{
                                        readOnly: true, // Make the field non-editable
                                        style: {
                                            color: "white",
                                            backgroundColor: "#FFFFFF1A",
                                            borderRadius: "8px",
                                        },
                                    }}
                                    fullWidth
                                />
                            </FormControl>


                            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                                <InputLabel shrink={false} sx={{ color: "gray !important" }}>
                                    Total Topup
                                </InputLabel>
                                <TextField
                                    name="total_topup"
                                    value={`${formik.values.total_topup} AUD`} // Append AUD to the value
                                    InputProps={{
                                        readOnly: true, // Make the field non-editable
                                        style: {
                                            color: "white",
                                            backgroundColor: "#FFFFFF1A",
                                            borderRadius: "8px",
                                        },
                                    }}
                                    fullWidth
                                />
                            </FormControl>

                        </Box>
                    </Box>
                </DialogContent>

                <Divider sx={{ backgroundColor: "gray", marginTop: "10px" }} />

                <DialogActions sx={{ padding: "20px", justifyContent: "center", backgroundColor: "#141414" }}>
                    <Box display="flex" justifyContent="center" gap={2}>
                        <Button
                            onClick={() => {
                                formik.resetForm();
                                onClose();
                            }}
                            sx={{
                                fontSize: '15px',
                                fontWeight: '50px',
                                width: '270px',
                                height: '48px',
                                backgroundColor: "transparent", // Make the background transparent
                                color: "white", // Make the text color white
                                borderRadius: "1234px",
                                textTransform: "none",
                                border: "1px solid white", // Add a white border (optional, to make the button visible)
                                transition: "background-color 0.3s ease, transform 0.3s ease", // Smooth transition for background color and scaling
                                "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.1)", // Slight background effect on hover
                                    transform: "scale(1.05)", // Slightly scale the button on hover for effect
                                },
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => formik.submitForm()} // Use submitForm for compatibility
                            sx={{
                                fontSize: '15px',
                                fontWeight: '50px',
                                width: '270px',
                                height: '48px',
                                backgroundColor: "#f2674a",
                                color: "white",
                                borderRadius: "1234px",
                                textTransform: "none",
                                transition: "background-color 0.3s ease, transform 0.3s ease", // Smooth transition for background color and scaling
                                "&:hover": {
                                    backgroundColor: "#e55b3a", // Darken the background on hover
                                    transform: "scale(1.05)", // Slightly scale the button on hover for effect
                                },
                                position: 'relative', // To position the spinner over the button
                            }}
                            disabled={formik.isSubmitting} // Disable the button while submitting
                        >
                            {formik.isSubmitting ? (
                                <CircularProgress size={24} sx={{ color: "orange", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }} />
                            ) : "Create"}
                        </Button>


                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default CreateAutoTopupPopup;