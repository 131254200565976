import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography, Box, Button, IconButton, CircularProgress, Avatar, ToggleButton } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import Axios from '../../../config/axios';
import { UrlConstant } from '../../../commom/UrlConstant';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { formatPrice, getCurrentUserId } from '../../../Utils/utils';
import UpgradeNowAiAnalysis from './UpgradeNowAiAnalysis';
import { useNavigate } from 'react-router-dom';
import { BookmarkBorderOutlined } from "@mui/icons-material";
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import gif from '../../../style/Gif/msgloading.gif'
import wolflogo from '../../../style/images/wolfpacklogo.png'



interface AiAnalysisProps {
  onClose: () => void;
  symbol: string;
  stockDetail: any;
  icon: any;
  tickerData: any;
  isWatchlist: any;
  CreateWatchList: any;
}

const AiAnalysis: React.FC<AiAnalysisProps> = ({ onClose, symbol, stockDetail, icon, tickerData, isWatchlist, CreateWatchList }) => {
  const navigate = useNavigate();
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [analysisData, setAnalysisData] = useState<string>('');
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [msg, setmsg] = useState(''); // State for controlling popup visibility
  const [Success, setSuccess] = useState(false); // State for controlling popup visibility

  // API call to fetch AI analysis
  const fetchAiAnalysis = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get(
        UrlConstant.AiAnalysis.getAiAnalysis.replace('{0}', symbol)
      );
      const resData = response.data;

      if (resData.status === true && resData.data) {
        setAnalysisData(resData.data[0]?.analysis || 'No analysis available.');
      } else if (resData.status === false) {
        setShowUpgrade(true);

      } else {
        setAnalysisData('Error: Unable to fetch analysis.');
      }
    } catch (error: any) {
      console.error('ERROR:', error);
      setAnalysisData('Error: Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (symbol) {
      fetchAiAnalysis();
    }
  }, [symbol]);

  const handleClose = () => {
    setShowUpgrade(false);
    onClose();
  }

  const onUpgradeNow = () => {
    setShowUpgrade(false);
    navigate('/premiumSubscription');
  }


  const onTrade = (stock, ticker) => {
    navigation("/stockdetail", {
      state: {
        symbol: ticker,
        title: "Ask Mr. Wolf",
        icon: stock?.branding?.icon_url ?? stock?.branding?.logo_url,
      },
    });
  }

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={onClose}
        PaperProps={{
          style: {
            background: '#141414',
            border: '1px solid #FFFFFF33',
            zIndex: 999,
            margin: '50px',
            minHeight: '50vh',
            maxHeight: '90vh',
            padding: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px',
            overflow: 'hidden',
          },
        }}
      >
        <DialogContent
          style={{
            textAlign: 'center',
            backgroundColor: '#000',
            color: '#fff',
            width: '100%',
            padding: '20px 0',
            overflow: 'hidden',
            position: 'relative',
            minHeight: '60vh', // Ensure a minimum height for a better user experience
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} padding={'0 10px'}>

            <Box className="text-white" display={"flex"} m={"10px"} >
              <Box display="flex" alignItems="center" gap="10px" >
                {icon ? (
                  <Avatar
                    alt={symbol}
                    src={icon}
                    sx={{
                      width: 64,
                      height: 64,
                      backgroundColor: "white",
                      objectFit: "fill",
                    }}
                    className="icon"
                  />
                ) : (
                  <Box
                    sx={{
                      width: 64,
                      height: 64,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "50%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#212529" }}
                    >
                      {symbol}
                    </Typography>
                  </Box>
                )}
                <Box display="flex" flexDirection="column" alignItems={"baseline"}  >
                  <Typography mb={1} variant="h5">
                    {stockDetail?.displayName}
                  </Typography>
                  <Typography variant="body1" className="text-secondary">
                    {stockDetail?.stockExchangeCode}:{stockDetail?.symbol}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" gap="10px" ml={"40px"}>
                <Typography variant="h5" fontSize={"32px"} textAlign="end">
                  ${formatPrice(tickerData?.lastPrice)}
                </Typography>
                <Typography variant="h5" fontSize={"32px"} style={{ color: tickerData.diffAmount == 0 ? "" : tickerData.diffAmount > 0 ? "#4edf6d" : "#ff2a2a" }}>

                  {tickerData.diffAmount == 0 ? "$" : tickerData.diffAmount > 0 ? "+$" : "-$"}
                  {formatPrice(Math.abs(tickerData.diffAmount))}
                  ({formatPrice(tickerData.percentage)}%)
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'end' }}>
              <Box>
                <ToggleButton
                  className="text-secondary"
                  value="watchlist"
                  sx={{
                    padding: "7px 15px !important",
                    borderRadius: "30px !important",
                    borderColor: "#FFFFFF33 !important",
                  }}
                  onClick={() => CreateWatchList(isWatchlist, symbol)}
                >
                  {
                    isWatchlist ? <BookmarkOutlinedIcon /> : <BookmarkBorderOutlined />
                  }

                  WatchList
                </ToggleButton>
                <Button
                  className="btn-primary btn"
                  sx={{ padding: '5px 35px', ml: "5px" }}
                  onClick={() => { onClose() }}
                >
                  Trade
                </Button>
              </Box>
              <IconButton sx={{ ml: "15px" }} onClick={onClose}>
                <CancelOutlined sx={{ height: '36px', width: '36px' }} />
              </IconButton>
            </Box>
          </Box>

          {/* Border */}
          <Box width={'100%'} height={'1px'} bgcolor={'#FFFFFF33'} my={'20px'} />


          {/* AI Analysis Content or Loader */}
          <Box textAlign="left" px={4} py={2} maxHeight="60vh" overflow="auto" minHeight="350px">
            {isLoading ? (
              <Box display="flex" alignItems="flex-start" justifyContent="left" height="100%">
                {/* <CircularProgress sx={{ color: '#FFFFFF' }} />
                <Typography mt={2} color="gray">Fetching AI Analysis...</Typography> */}
                <Avatar src={wolflogo}></Avatar>
                <img src={gif} alt="" height='25px' style={{ margin: "5px 0 5px 5px" }} />
              </Box>
            ) : (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{analysisData}</ReactMarkdown>
            )}
          </Box>

          {/* Footer Disclaimer */}
          <Box width={'100%'} height={'1px'} bgcolor={'#FFFFFF33'} my={'20px'} />
          <Box textAlign="center" px={4} >
            <Typography variant="body2" fontSize={"12px"} color="gray">
              DISCLAIMER: This data is for informational purposes only and should not be relied upon for trading or tax-related decisions. Any use of this data to buy or sell stocks or ETFs is at your own risk. The data may not be copied, reproduced, distributed, displayed, sold, or otherwise exploited in any form. It is sourced from third-party providers and may be subject to delays. Wolfpack Financial Inc. makes no guarantees regarding the reliability, accuracy, or completeness of third-party materials and accepts no liability for any errors or omissions in such materials. For detailed information on specific stocks and third-party disclosures, please visit Wolfpack’s website. Wolfpack Financial Inc., along with its subsidiaries, affiliates, partners, directors, officers, and staff, is not responsible for any actions taken based on the information provided through our services. Always consult a qualified professional before making financial decisions.            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
      {showUpgrade ? <UpgradeNowAiAnalysis onClose={handleClose} confirm_process={onUpgradeNow} /> : null}

    </>
  );
};

export default AiAnalysis;
